import Cookies from 'js-cookie';
import { put, select, takeLatest } from 'redux-saga/effects';

import { api } from 'src/api';
import { dataLayerPush } from 'src/helpers/gtm';
import { addDays, format } from 'date-fns';
import { getMobilePlatform } from '../view/selectors';

import {
  GET_DEEPLINK,
  GO_MOBILE_APP_POPUP,
  MOVE_TO_APP_CONTINUED_WEB,
  MOVE_TO_APP_OPENED_APP,
  MOVE_TO_APP_SHOWN,
  UTM_KEY_COOKIE,
} from './constants';
import { setMobileAppData, setShortLink } from './slice';
import { parseUTMParams, transformUTMKey } from './utils';

function* handleGoMobileApp() {
  const platform = yield select(getMobilePlatform);
  try {
    const response =
      platform === 'android'
        ? yield api.getBannersAndroidAppData()
        : yield api.getBannersIosAppData();

    if (response) {
      yield put(setMobileAppData(response));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* handleGetDeeplink() {
  try {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const nonMarketingParams = new URLSearchParams();
    // удаляем query-параметры aflt и utm
    [...currentUrlParams.keys()]
      .filter((param) => param !== 'aflt' && !param.includes('utm'))
      .forEach((param) =>
        nonMarketingParams.append(param, currentUrlParams.get(param) || ''),
      );
    const ltDomen = 'https://level.travel';
    const preparingUrl = `${ltDomen}${window.location.pathname}`;
    const newQueryParams = new URLSearchParams();

    // preparingUrl для fallback_url
    const { origin } = window.location;
    const preparingUrlFallback = `${origin}${window.location.pathname}`;

    // добавляем метки маркетинга из Cookies
    const cookieUtmValue = Cookies.get(UTM_KEY_COOKIE);
    if (cookieUtmValue) {
      parseUTMParams(cookieUtmValue)?.forEach((utmParam) => {
        const [key, value] = utmParam.split('=');
        const transformedKey = transformUTMKey(key);
        newQueryParams.append(transformedKey, value);
      });
    }

    // добавляем метку партнера из Cookie
    const afltLink = Cookies.get('last_cookie_link');
    if (afltLink) {
      newQueryParams.append(
        'aflt',
        new URLSearchParams(new URL(afltLink).search).get('aflt') || '',
      );
      newQueryParams.append(
        'aflt_expires_at',
        format(addDays(Date.now(), 30), 'yy-MM-dd'),
      );
    }

    // Объединяем обычный query-параметры с маркетинговыми
    const url = new URL(
      `${preparingUrl}?${
        nonMarketingParams.entries.length
          ? `${nonMarketingParams.toString()}&`
          : ''
      }${newQueryParams.toString()}`,
    );

    // Объединяем обычный query-параметры с маркетинговыми для fallback_url
    const fallback_url = new URL(
      `${preparingUrlFallback}?${
        nonMarketingParams.entries.length
          ? `${nonMarketingParams.toString()}&`
          : ''
      }${newQueryParams.toString()}`,
    );

    const response = yield api.postDeeplinkGenerator({
      url: url.href,
      fallback_url: fallback_url.href,
      temporary: true,
    });
    const { shortUrl } = response;
    yield put(setShortLink(shortUrl));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// Analitics for banners
function isBannerShown() {
  dataLayerPush({
    event: 'move_to_app_shown',
  });
}

function onClickAppBanner() {
  dataLayerPush({
    event: 'move_to_app_opened_app',
  });
}

function onClickStayButton() {
  dataLayerPush({
    event: 'move_to_app_continued_web',
  });
}

export default function* appBannersSaga() {
  yield takeLatest(GO_MOBILE_APP_POPUP, handleGoMobileApp);
  yield takeLatest(GET_DEEPLINK, handleGetDeeplink);
  yield takeLatest(MOVE_TO_APP_OPENED_APP, onClickAppBanner);
  yield takeLatest(MOVE_TO_APP_CONTINUED_WEB, onClickStayButton);
  yield takeLatest(MOVE_TO_APP_SHOWN, isBannerShown);
}
