/* eslint-disable no-console */
import type { SagaReturnType } from 'redux-saga/effects';
import { put, takeLatest, all, select } from 'redux-saga/effects';
import { upperFirst } from 'lodash';

// Utils:
import type { PostOrdersPay } from '@lt/api/dist/methods/orders/types';
import type {
  postUnitellerPay,
  postUnitellerPay3ds,
  postUnitellerPay3ds_2_0,
} from '@lt/api/dist/methods/uniteller/types';
import { logErrorToSentry } from 'src/error/utils/logErrorToSentry';
import { ERROR_TYPE } from 'src/routes/Checkout/store/domains/error/constants';
import { sendPayAnalytics } from 'src/routes/Checkout/store/paymentAnalytics/sagas';
import { getPaymentRedirectUrl } from '../../utils/stringUtils';

// Constants:
import { PAY_ORDER, UNKNOWN_ERROR } from './constants';

import { api } from '../../api';

// Selectors:
import {
  getOrderId,
  getOrderPaymentId,
  getPaymentMethod,

  // amount:
  getPaymentAmount,

  // 3ds:
  getPayment3dsPares,

  // Paylate:
  getIsPaylate,

  // Card info:
  getCardData,

  // Uniteller:
  getUnitellerPaymentId,
  getPayment3dsCres,
  getShow3dsHiddenPopup,
  getOrderPin,
} from './selectors';

// Actions:
import {
  // Default payment:
  initPaymentAction,
  initPaymentSuccessAction,

  // Uniteller payment:
  payUnitellerAction,
  payUnitellerSuccessAction,
  payUnitellerFailAction,

  // 3DS:
  pay3dsAction,
  pay3dsSubmitParesAction,
  pay3dsSubmitCresAction,
  pay3dsFailAction,

  // 3DS 2.0:
  submit3dsDataAction,
  submit3dsDataFailAction,

  // Show 3ds popup:
  show3dsPopupAction,
  hide3dsPopupAction,

  // Card data:
  show3dsHiddenPopupAction,
  hide3dsHiddenPopupAction,

  // Order paid success:
  orderPaidSuccessAction,

  // url for paylate
  setFormUrlAction,
  startPayment,
} from './reducer';
import { searchTypeSelector } from '../view/selectors';

/**
 * Формируем заказ
 * Кидаем в метод оплаты данные карты и id заказа
 */
function* initPayment() {
  try {
    yield put(startPayment());
    const orderId: SagaReturnType<typeof getOrderId> = yield select(getOrderId);
    const paymentMethod: SagaReturnType<typeof getPaymentMethod> = yield select(
      getPaymentMethod,
    );
    const orderPaymentId: SagaReturnType<typeof getOrderPaymentId> =
      yield select(getOrderPaymentId);

    if (!orderId) {
      throw new Error(
        `initPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что orderId ${orderId}`,
      );
    }
    if (!paymentMethod) {
      throw new Error(
        `initPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что paymentMethod ${paymentMethod}`,
      );
    }
    const isSbp = paymentMethod === 'sbp';
    const payOrderData: PostOrdersPay = {
      order_id: orderId,
      payment_method: paymentMethod,
      payment_number: orderPaymentId || isSbp ? undefined : 1,
    };

    const response: SagaReturnType<typeof api.postOrdersPay> =
      yield api.postOrdersPay(payOrderData);

    const {
      orderId: responseOrderId,
      orderIDP,
      paymentId: sbpPaymentId,
      formURL,
    } = response;
    if (formURL) {
      yield put(setFormUrlAction(formURL));
    }
    const isPaylate: SagaReturnType<typeof getIsPaylate> = yield select(
      getIsPaylate,
    );
    const paymentId = isPaylate ? responseOrderId : orderIDP;

    if (!paymentId && !isSbp) {
      yield put(
        pay3dsFailAction({
          type: ERROR_TYPE.ORDER,
          action: PAY_ORDER,
          message: 'Не получен Order_IDP',
        }),
      );
      return;
    }

    if (isSbp) {
      const pin: SagaReturnType<typeof getOrderPin> = yield select(getOrderPin);
      const searchType: SagaReturnType<typeof searchTypeSelector> =
        yield select(searchTypeSelector);
      window.location.href = `${window.location.origin}/payment/sbp?payment_id=${sbpPaymentId}&order_id=${orderId}&pin=${pin}&search_type=${searchType}`;
      return;
    }

    yield put(initPaymentSuccessAction({ orderPaymentId: paymentId ?? null }));
  } catch (error) {
    console.error(error, 'Не удалось сформировать заказ на оплату');
    logErrorToSentry(error as Error, 'Не удалось сформировать заказ на оплату');
    yield put(
      pay3dsFailAction({
        type: ERROR_TYPE.ORDER,
        action: PAY_ORDER,
        message: 'Не удалось сформировать заказ на оплату',
      }),
    );
  }
}

/**
 * Получаем id заказа и кидаем его в юнителлер
 */
function* handlePaymentSuccess() {
  const isPaylate: SagaReturnType<typeof getIsPaylate> = yield select(
    getIsPaylate,
  );
  if (!isPaylate) {
    yield put(payUnitellerAction());
  } else {
    yield put(orderPaidSuccessAction());
  }
}

/**
 * Обработка платежа uniteller
 */
function* handleUnitellerPayment() {
  try {
    const orderId: SagaReturnType<typeof getOrderId> = yield select(getOrderId);
    const payment_id: SagaReturnType<typeof getOrderPaymentId> = yield select(
      getOrderPaymentId,
    );
    const cardData: SagaReturnType<typeof getCardData> = yield select(
      getCardData,
    );
    const amount: SagaReturnType<typeof getPaymentAmount> = yield select(
      getPaymentAmount,
    );

    if (!cardData) {
      throw new Error(`Не пришли данные cardData: ${cardData}`);
    }
    const { number: pan, expMonth, expYear, cvv } = cardData;

    if (!orderId) {
      throw new Error(
        `handleUnitellerPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что orderId: ${orderId}`,
      );
    }
    if (!amount) {
      throw new Error(
        `handleUnitellerPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что amount: ${amount}`,
      );
    }
    if (!payment_id) {
      throw new Error(
        `handleUnitellerPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что payment_id: ${payment_id}`,
      );
    }
    const params: postUnitellerPay = {
      pan,
      cvv,
      exp_month: expMonth,
      exp_year: `20${expYear}`,
      amount,
      payment_id,
      return_url: window.origin,
      order_id: orderId,
      redirect_url: getPaymentRedirectUrl(),
      name: 'A',
      surname: 'A',
      browser_info: {
        timezoneOffset: new Date().getTimezoneOffset(),
        colorDepth: window.screen.colorDepth,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        language: window.navigator.language,
        userAgent: navigator.userAgent,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      },
    };
    const response: SagaReturnType<typeof api.postUnitellerPay> & {
      error?: string;
    } = yield api.postUnitellerPay(params);

    if (
      !(
        response &&
        typeof response === 'object' &&
        'success' in response &&
        'data' in response &&
        // 'error' in response &&
        typeof response.data === 'object' &&
        response.data &&
        'required_3ds' in response.data &&
        'payment_id' in response.data &&
        // payment_id у нас везде стринга формата <номер заказа>-<номере платежа в заказе>
        // но тут апишка возвращает id платежа числом
        typeof response.data.payment_id === 'number'
      )
    ) {
      throw new Error(`Response timed out: ${JSON.stringify(response)}`);
    }

    const { success, data, error } = response;

    // TODO: LT-38025 унифицировать тип, всегда должна быть строка
    const paymentId = response.data.payment_id.toString();

    // Обработка того, что платеж уже прошел на бэке
    if (success) {
      yield put(orderPaidSuccessAction());
      return;
    }

    /**
     * Если есть three_ds_method – вызываем скрытый popup для дополнительного
     * шага 3DsMethod(по сути псевдоавторизация 3DS) для 3DS 2.0:
     */
    if (data && data.required_3ds && data['3ds'].three_ds_method) {
      yield put(payUnitellerSuccessAction(paymentId));
      yield put(show3dsHiddenPopupAction(data['3ds'].three_ds_method));
      return;
    }

    // Обычный флоу 3ds, без дополнительного шага:
    if (data && data.required_3ds) {
      yield put(payUnitellerSuccessAction(paymentId));
      yield put(show3dsPopupAction(data['3ds']));
      return;
    }

    yield put(
      payUnitellerFailAction({
        type: ERROR_TYPE.ORDER,
        action: PAY_ORDER,
        message:
          error && typeof error === 'string' && error?.length !== 0
            ? upperFirst(error)
            : UNKNOWN_ERROR,
      }),
    );
  } catch (error) {
    logErrorToSentry(
      error as Error,
      'Произошла ошибка оплаты через сервис Uniteller',
    );
    yield put(
      payUnitellerFailAction({
        type: ERROR_TYPE.ORDER,
        action: PAY_ORDER,
        message: UNKNOWN_ERROR,
      }),
    );
  }
}

/**
 * Проводит оплату с 3ds
 */
function* handle3dsPayment() {
  try {
    const pares: SagaReturnType<typeof getPayment3dsPares> = yield select(
      getPayment3dsPares,
    );
    const cres: SagaReturnType<typeof getPayment3dsCres> = yield select(
      getPayment3dsCres,
    );

    if (!pares && !cres) {
      throw new Error(
        `handle3dsPayment оплата не прошла, потому что нет parses: ${pares} или cres: ${cres}`,
      );
    }

    const unitellerPaymentId: SagaReturnType<typeof getUnitellerPaymentId> =
      yield select(getUnitellerPaymentId);

    if (!unitellerPaymentId) {
      throw new Error(
        `handle3dsPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что payment_id: ${unitellerPaymentId}`,
      );
    }
    // Просто прокидываем все на бэк, бэк сам разбирается, что взять :D
    const params: postUnitellerPay3ds = {
      pares: pares || undefined,
      cres,
      payment_id: unitellerPaymentId,
    };
    const response: SagaReturnType<typeof api.postUnitellerPay3ds> =
      yield api.postUnitellerPay3ds(params);

    if (
      response &&
      typeof response === 'object' &&
      'success' in response &&
      response.success
    ) {
      yield put(orderPaidSuccessAction());
      return;
    }

    yield put(
      pay3dsFailAction({
        type: ERROR_TYPE.ORDER_3DS,
        action: PAY_ORDER,
        message:
          response &&
          typeof response === 'object' &&
          'error' in response &&
          typeof response.error === 'string' &&
          response.error.length !== 0
            ? upperFirst(response.error)
            : UNKNOWN_ERROR,
      }),
    );
  } catch (error) {
    console.error(error, '3ds payment:Не удалось оплатить заказ');
    logErrorToSentry(error as Error, '3ds payment:Не удалось оплатить заказ');
    yield put(
      pay3dsFailAction({
        type: ERROR_TYPE.ORDER,
        action: PAY_ORDER,
        message:
          'Не удалось оплатить заказ. Если у вас включён VPN, выключите его и повторите оплату',
      }),
    );
  }
}

/**
 * Обрабатывает сабмит 3ds формы
 */
function* handle3dsSubmit() {
  const pares: SagaReturnType<typeof getPayment3dsPares> = yield select(
    getPayment3dsPares,
  );
  const cres: SagaReturnType<typeof getPayment3dsCres> = yield select(
    getPayment3dsCres,
  );

  yield put(hide3dsPopupAction());

  // Сабмитим оплату на бэк, если фрейм 3ds успешно пройден
  if (pares || cres) {
    yield put(pay3dsAction());
  } else {
    yield put(
      pay3dsFailAction({
        type: ERROR_TYPE.ORDER_3DS,
        action: PAY_ORDER,
        message: 'Не удалось получить ответ от банка при проверке 3-D Secure',
      }),
    );
  }
}

/**
 *  Проверка версии 3ds, если версия 2.0 то запускаем доволнительный шаг
 */
function* handleSubmit3dsData() {
  yield put(hide3dsHiddenPopupAction());
  const unitellerPaymentId: SagaReturnType<typeof getUnitellerPaymentId> =
    yield select(getUnitellerPaymentId);

  try {
    if (!unitellerPaymentId) {
      throw new Error(
        `handleSubmit3dsData ОПЛАТА НЕ ПРОЙДЕТ, потому что payment_id: ${unitellerPaymentId}`,
      );
    }

    const params: postUnitellerPay3ds_2_0 = {
      payment_id: unitellerPaymentId,
    };
    const response: SagaReturnType<typeof api.postUnitellerPay3ds_2_0> =
      yield api.postUnitellerPay3ds_2_0(params);

    if (
      !(
        response &&
        typeof response === 'object' &&
        'success' in response &&
        'status' in response &&
        typeof response.status === 'string' &&
        'data' in response
      )
    ) {
      throw new Error(
        `handleSubmit3dsData пришел не правильный ответ response: ${JSON.stringify(
          response,
        )}`,
      );
    }
    // Если статус `paid` – значит оплата прошла успешно
    if (response.success && response.status === 'paid') {
      yield put(orderPaidSuccessAction());
      return;
    }

    // Если статус `required_3ds` – значит нужно дополнительно пройти 3ds Flow
    if (response.success && response.status === 'required_3ds') {
      yield put(show3dsPopupAction(response.data));
      return;
    }
    throw new Error('handleSubmit3dsData видимо что-то пошло не так');
  } catch (error) {
    logErrorToSentry(error as Error, unitellerPaymentId);
    yield put(submit3dsDataFailAction());
  }
}

function* handleSubmit3dsDataFailed() {
  const show3dsHiddenPopup: SagaReturnType<typeof getShow3dsHiddenPopup> =
    yield select(getShow3dsHiddenPopup);
  if (!show3dsHiddenPopup) yield put(hide3dsHiddenPopupAction());
  yield put(
    pay3dsFailAction({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message:
        'Не удалось оплатить заказ. Если у вас включён VPN, выключите его и повторите оплату',
    }),
  );
}

export default function* paymentSaga() {
  yield all([
    // Payment:
    // Default:
    takeLatest(initPaymentAction, initPayment),
    takeLatest(initPaymentSuccessAction, handlePaymentSuccess),

    // Uniteller:
    takeLatest(payUnitellerAction, handleUnitellerPayment),

    // 3DS:
    takeLatest(pay3dsAction, handle3dsPayment),
    takeLatest(pay3dsSubmitParesAction, handle3dsSubmit),
    takeLatest(pay3dsSubmitCresAction, handle3dsSubmit),
    // 3DS 2.0:
    takeLatest(submit3dsDataAction, handleSubmit3dsData),
    takeLatest(submit3dsDataFailAction, handleSubmit3dsDataFailed),
    takeLatest(
      [
        pay3dsFailAction,
        payUnitellerFailAction,
        startPayment,
        orderPaidSuccessAction,
      ],
      sendPayAnalytics,
    ),
  ]);
}
