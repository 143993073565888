import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { isPaylate } from './utils';
import { getImmutableCompatible } from '../../utils/immutableUtils';
import { PAYMENT_METHOD_CARD } from './constants';
import type { PaymentStateType } from './types';

// selectors
export const getPayment = (state): PaymentStateType =>
  getImmutableCompatible(state, 'payment');

/**
 * If payment still in process;
 */
export const getPaymentIsProcessing = createSelector(
  getPayment,
  (payment) => payment.isProcessing,
);

/**
 * If payment succeeded;
 */
export const getPaymentIsSucceeded = createSelector(
  getPayment,
  (payment) => payment.isPaid,
);

/**
 * If payment faliled;
 */
export const getPaymentIsFailed = createSelector(getPayment, (payment) =>
  Boolean(payment.error),
);

/**
 * Returns payment error message if present;
 */
export const getPaymentErrorMessage = createSelector(
  getPayment,
  (payment) => payment.error?.message,
);

/**
 * Payment amount
 */
export const getPaymentAmount = createSelector(
  getPayment,
  (payment) => payment.paymentAmount,
);

/**
 * 3ds Popup:
 */
export const getShow3dsPopup = createSelector(
  getPayment,
  (payment) => payment.show3dsPopup,
);

/**
 * 3ds Hidden popup
 */
export const getShow3dsHiddenPopup = createSelector(
  getPayment,
  (payment) => payment.show3dsHiddenPopup,
);

/**
 * 3ds Pares/Cres:
 */
export const getPayment3dsPares = createSelector(
  getPayment,
  (payment) => payment['3dsPaRes'],
);
export const getPayment3dsCres = createSelector(
  getPayment,
  (payment) => payment['3dsCres'],
);

/**
 *  3ds/3dsHidden Data
 */
export const getPayment3dsData = createSelector(
  getPayment,
  (payment) => payment['3ds'],
);
export const getPayment3dsHiddenData = createSelector(
  getPayment,
  (payment) => payment['3dsHidden'],
);

/**
 * Order info
 */
export const getOrderId = createSelector(
  getPayment,
  (payment) => payment.orderId,
);
export const getOrderPin = createSelector(
  getPayment,
  (payment) => payment.orderPin,
);

/**
 * Payment info
 */
export const getOrderPaymentId = createSelector(
  getPayment,
  (payment) => payment.orderPaymentId,
);

/**
 * Uniteller payment id
 */
export const getUnitellerPaymentId = createSelector(
  getPayment,
  (payment) => payment.unitellerPaymentId,
);

/**
 * Order is paid
 */
export const getOrderIsPaid = createSelector(
  getPayment,
  (payment) => payment.isPaid,
);

/**
 * Card data
 */
export const getCardData = createSelector(
  getPayment,
  (payment) => payment.cardData,
);
export const getCardIsValid = createSelector(
  getPayment,
  (payment) => payment.cardIsValid,
);

/**
 * Payment method:
 */
export const getPaymentMethod = createSelector(
  getPayment,
  (payment) => payment.paymentMethod,
);
export const getPaymentMethodList = createSelector(getPayment, (payment) =>
  payment.paymentMethodList?.filter((method) => method.enable),
);

export const selectIsSbpIncluded = createSelector(
  getPaymentMethodList,
  (methods) => Boolean(methods.find((method) => method.type === 'sbp')),
);

export const getDefaultPaymentMethod = createSelector(
  getPaymentMethodList,
  (paymentMethods: unknown[]) => {
    if (
      !(
        paymentMethods &&
        Array.isArray(paymentMethods) &&
        paymentMethods.length > 0
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    const paymentMethod = paymentMethods[0];
    if (
      !(
        paymentMethod &&
        typeof paymentMethod === 'object' &&
        'type' in paymentMethod &&
        paymentMethod.type === 'string'
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    return paymentMethod.type;
  },
);
export const hasPaymentMethod = createSelector(
  getPayment,
  (paymentMethods) => !isEmpty(paymentMethods.paymentMethodList),
);
export const getPaymentData = createSelector(
  [getPaymentMethodList, getPaymentMethod],
  (paymentMethods = [], orderPaymentMethod) =>
    paymentMethods.find((item) => item.type === orderPaymentMethod) || {},
);

/**
 * Возвращает `true` если метод оплаты Paylate
 */
export const getIsPaylate = createSelector(getPaymentMethod, isPaylate);

/**
 * Возвращает ссылку для Paylate
 */
export const getFormUrl = createSelector(
  getPayment,
  (payment) => payment.formUrl,
);
