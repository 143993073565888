/**
 * Сейчас Footer генерится в рубях
 * импортирую стили, чтобы они попали в общий чанк
 *
 * // TODO: перенести футер на реакт
 */

import React from 'react';

import './styles/index.scss';

const Footer = () => <footer />;

export default Footer;
