import React, { useEffect } from 'react';

import { useCountry } from '@lt/localization';

import MobileAppBanner from 'src/components/MobileAppBanner';
import classnames from 'classnames';
import DynamicDataArray from '../DynamicDataArray/DynamicDataArray';
import useDynamicInfo from '../../hooks/useDynamicInfo';
import styles from './dynamicDataWrapper.module.css';

export const DynamicDataWrapper = ({
  fetchLiveInfoPromo,
  liveInfoPromoRequest,
  isMobile,
  isWhitelabel,
  children,
  appLink,
  getDeeplink,
  isWebview,
  isAppBannerEnabled,
}) => {
  const { isRussia } = useCountry();
  const {
    currentLatestOrder,
    currentHotPriceByCountry,
    currentFoundToursToday,
  } = useDynamicInfo();

  useEffect(() => {
    if (!appLink) getDeeplink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataArrivedSuccessfully =
    liveInfoPromoRequest.isLoading !== null &&
    !liveInfoPromoRequest.isLoading &&
    !liveInfoPromoRequest.isError;

  const latestOrder = currentLatestOrder && {
    title: currentLatestOrder.title,
    descriptions: currentLatestOrder.descriptions,
  };

  const hotPriceByCountry = currentHotPriceByCountry && {
    name: currentHotPriceByCountry.name,
    price: currentHotPriceByCountry.price,
  };

  useEffect(() => {
    fetchLiveInfoPromo();
  }, [fetchLiveInfoPromo]);

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerMobile]: isMobile,
        [styles.containerDesktop]: !isMobile,
        [styles.containerWebview]: isWebview,
      })}
    >
      <div
        className={classnames({
          [styles.searcherWrapperDesktop]: !isMobile,
        })}
      >
        {children}
        {!isWebview && (
          <div
            className={classnames({
              [styles.searcherFooterMobile]: isMobile,
              [styles.searcherFooterDesktop]: !isMobile,
            })}
          >
            {isRussia ? (
              <DynamicDataArray
                foundToursToday={currentFoundToursToday || null}
                latestOrder={latestOrder}
                hotPriceByCountry={hotPriceByCountry}
                isLoading={!dataArrivedSuccessfully}
                isMobile={isMobile}
                isWhitelabel={isWhitelabel}
              />
            ) : null}
            {!isMobile && isAppBannerEnabled && (
              <div className={styles.appBannersWrapper}>
                <MobileAppBanner appLink={appLink} deviceType="android" />
                <MobileAppBanner appLink={appLink} deviceType="ios" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
