import React from 'react';

import { SCROLLABLE_CONTAINER_MOBILE_TEST_ID } from '../../constants';
import type { IScrollableContainer } from '../../types';
import styles from './scrollableContainerMobile.module.css';

export const ScrollableMobileContainer = (
  props: IScrollableContainer,
): JSX.Element => {
  const { children } = props;

  return (
    <div
      className={styles.wrapper}
      data-testid={SCROLLABLE_CONTAINER_MOBILE_TEST_ID}
    >
      {children}
    </div>
  );
};

export default ScrollableMobileContainer;
