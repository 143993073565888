import React from 'react';
import type { Menu } from 'src/store/menu/types';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

interface Props {
  isMobile: LT.isMobile;
  isWhitelabel?: boolean;
  menu: Menu;
  className?: string;
  isCollapsed?: boolean;
  isHideMenu?: boolean;
  isHideFeedback: boolean;
  openSidebar?: () => void;
}

const HeaderMenu = ({
  isMobile,
  isWhitelabel = false,
  isHideMenu = false,
  menu,
  className,
  isCollapsed,
  openSidebar,
  isHideFeedback,
}: Props): JSX.Element | null => {
  if (isHideMenu) {
    return null;
  }

  return isMobile ? (
    <>{openSidebar && <MobileMenu openSidebar={openSidebar} />}</>
  ) : (
    <DesktopMenu
      className={className}
      showAuthorizationBlock={isWhitelabel || Boolean(menu.profile)}
      showWishlist={Boolean(menu?.wishlist)}
      services={menu?.services}
      help={menu?.help}
      isCollapsed={isCollapsed}
      showFeedback={!isHideFeedback}
    />
  );
};

export default HeaderMenu;
