import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import { clearPreviousPackageDataAction } from 'checkout/store/actions/package';

import type { OrderExtra } from 'checkout/store/order/types';
import type { FlightsData } from '../../types/flights';
import { CHANGE_SELECTED_FLIGHT_CLASS, LOADING_STATE } from './constants';
import type { LoadingState } from './types';

export type FlightsSlice = {
  economFlights: FlightsData[];
  businessFlights: FlightsData[];
  visibleFlightsCount: number;
  loadingState: LoadingState;
};

export const initialState: FlightsSlice = {
  economFlights: [],
  businessFlights: [],
  visibleFlightsCount: 3,
  loadingState: LOADING_STATE.IS_LOADING,
};

export const name = 'flights';

const flightsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setEconomFlights: (state, action: PayloadAction<FlightsData[]>) => {
      state.economFlights = action.payload;
    },
    setBusinessFlights: (state, action: PayloadAction<FlightsData[]>) => {
      state.businessFlights = action.payload;
    },
    setVisibleFlights: (state, action: PayloadAction<number>) => {
      state.visibleFlightsCount = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<LoadingState>) => {
      state.loadingState = action.payload;
    },
    /*
      Очищать стор от старых рейсов при применении фильтров
    */
    clearFlightsDuringFiltration: (state) => ({
      ...state,
      economFlights: [],
      businessFlights: [],
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(clearPreviousPackageDataAction, () => initialState),
});

export const {
  setEconomFlights,
  setBusinessFlights,
  setLoadingState,
  clearFlightsDuringFiltration,
  setVisibleFlights,
} = flightsSlice.actions;

export const toggleFlightClassAction = createAction<{
  flight_pair_id: string;
  originalFlight: string | null;
  extras?: OrderExtra[];
}>('TOGGLE_FLIGHT_CLASS');

export const fetchFlightsAction = createAction('FETCH_FLIGHTS');

export const origPackageFlightsFetchedAction = createAction(
  'ORIG_PACKAGE_FLIGHTS_FETCHED',
);

export const flightsSetAction = createAction('FLIGHTS_SET');

export const fligthsFetchingDoneAction = createAction('FLIGHTS_FETCHING_DONE');

export const changeFlightClassAction = createAction<string>(
  CHANGE_SELECTED_FLIGHT_CLASS,
);

export default flightsSlice.reducer;
