import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

// Actions
import { changeTouristData } from 'src/store/tourists/slice';
import { toggleFlightClassAction } from 'checkout/CheckoutRoutes/PackageCheckout/store/flights/slice';
import {
  confirmClientAction,
  resetClientAction,
} from 'src/store/client/actions';
import { resetTouristConfirmedAction } from '../actions/tourists';
import { clearPreviousPackageDataAction } from '../actions/package';
import { validationSuccessAction } from '../validation/actions';

import { ORDER_COMMENT_CHECKBOXES } from '../constants/order';

import type {
  OrderErrors,
  OrderStateType,
  UpdateFlightInfoPayload,
  SetFlightInfoPayload,
  OrderComment,
  PrebuildOrderPayload,
  PrebuildOrderSuccessPayload,
  SetPaylateDataPayload,
  PrebuildOrderCompleteWithMindboxErrorsPayload,
  CreateOrderSuccessPayload,
  CreateOrderFailPayload,
  UpdateOrderSuccessPayload,
} from '../order/types';

const initialState: OrderStateType = {
  isCreating: false,
  jointOrder: {
    ordersAmount: 0,
    firstOrderNumber: 0,
    isFirstOrder: null,
  },
  isProcessed: false,
  isPrebuilt: false,
  isClientConfirmed: false,
  bonuses: {
    cashback_identifier: null,
    bonuses_absolut: null,
    bonus_total: 0,
    fuelfree_bonuses_relative: 0,
    max_value: null,
    promotion_fixed: null,
    promotion_value: 0,
  },
  isTouristsConfirmed: false,
  showOrderErrorPopup: false,
  priceInfo: [],
  isFinished: false,
  isPrebuildFetching: false,
  isProcessing: false,
  isCreated: false,
  comment: {
    text: '',
    checkboxes: ORDER_COMMENT_CHECKBOXES,
  },
};

const updateFlightInfo = (
  state: OrderStateType,
  payload: UpdateFlightInfoPayload,
) => {
  const { flight_pair_id, extras, originalFlight } = payload;
  return {
    ...state,
    flight_pair_id,
    flight_extras: extras,
    originalFlight,
  };
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // Flights
    selectFlight: (state, action: PayloadAction<UpdateFlightInfoPayload>) =>
      updateFlightInfo(state, action.payload),
    skipFlights: (state) => ({
      ...state,
      isSkipFlights: true,
    }),
    setFlightInfo: (state, action: PayloadAction<SetFlightInfoPayload>) => ({
      ...state,
      flightInfo: action.payload,
    }),
    // Order Comment
    changeOrderComment: (state, action: PayloadAction<OrderComment>) => ({
      ...state,
      comment: action.payload,
    }),
    /**
     * Добавление в стор общего числа заказов,
     * если выбраны совместные заказы
     */
    setOrdersAmount: (state, action: PayloadAction<number>) => ({
      ...state,
      jointOrder: {
        ...state.jointOrder,
        ordersAmount: action.payload,
      },
    }),
    /**
     * Добавление в стор номера первого заказа,
     * если выбраны совместные заказы
     */
    setFirstOrderNumber: (state, action: PayloadAction<number>) => ({
      ...state,
      jointOrder: {
        ...state.jointOrder,
        firstOrderNumber: action.payload,
      },
    }),
    /**
     * Изменение заказа с дополнительного на первый
     * либо наоборот, если выбраны совместные заказаы
     */
    switchOrder: (state) => ({
      ...state,
      jointOrder: {
        ...state.jointOrder,
        isFirstOrder: !state.jointOrder.isFirstOrder,
      },
    }),
    /**
     * Если выбраны совместные заказы по умолчанию грузится первый заказ
     */
    setFirstOrderField: (state, action: PayloadAction<boolean | null>) => ({
      ...state,
      jointOrder: {
        ...state.jointOrder,
        isFirstOrder: action.payload,
      },
    }),
    // Preuild Order
    prebuildOrder: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: PayloadAction<PrebuildOrderPayload | undefined>,
    ) => ({
      ...state,
      isPrebuildFetching: true,
    }),
    prebuildOrderFail: (state, action: PayloadAction<OrderErrors>) => ({
      ...state,
      isPrebuildFetching: false,
      errors: action.payload,
    }),
    prebuildOrderSuccess: (
      state,
      action: PayloadAction<PrebuildOrderSuccessPayload>,
    ) => {
      const {
        priceInfo,
        fullPrice,
        bonuses,
        coupon,
        discount_methods,
        discount_price,
        discount_type,
        insurance_price,
        order_extras,
      } = action.payload;

      return {
        ...state,
        priceInfo,
        fullPrice,
        bonuses,
        coupon,
        discount_methods,
        discount_price,
        discount_type,
        order_extras,
        insurance_price,
        isPrebuildFetching: false,
        isPrebuilt: true,
        errors: null,
      };
    },
    prebuildOrderCompleteWithMindboxErrors: (
      state,
      action: PayloadAction<PrebuildOrderCompleteWithMindboxErrorsPayload>,
    ) => {
      const {
        data: {
          order: {
            priceInfo,
            fullPrice,
            bonuses,
            coupon,
            discount_methods,
            discount_price,
            discount_type,
            insurance_price,
            order_extras,
          },
          order_errors,
        },
      } = action.payload;

      return {
        ...state,
        priceInfo,
        fullPrice,
        bonuses,
        coupon,
        discount_methods,
        discount_price,
        discount_type,
        order_extras,
        insurance_price,
        isPrebuildFetching: false,
        isPrebuilt: true,
        errors: order_errors,
      };
    },
    // Create order
    createOrder: (state) => ({
      ...state,
      isCreating: true,
    }),
    createOrderSuccess: (
      state,
      action: PayloadAction<CreateOrderSuccessPayload>,
    ) => {
      const { id, pin } = action.payload;

      return {
        ...state,
        id,
        pin,
        isCreated: true,
        isCreating: false,
      };
    },
    createOrderFail: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: PayloadAction<CreateOrderFailPayload>,
    ) => ({
      ...state,
      isCreating: false,
    }),
    // Update order
    updateOrder: (
      state,
      action: PayloadAction<Partial<OrderStateType> | undefined>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    updateOrderSuccess: (
      state,
      action: PayloadAction<UpdateOrderSuccessPayload>,
    ) => {
      const { price_info, full_price, payment_methods } = action.payload;

      return {
        ...state,
        priceInfo: price_info,
        fullPrice: full_price,
        payment_methods,
      };
    },
    setPaylateData: (state, action: PayloadAction<SetPaylateDataPayload>) => ({
      ...state,
      paylateData: action.payload,
    }),
    // Sub checks
    stashUserPriceBeforePay: (
      state,
      action: PayloadAction<number | undefined>,
    ) => ({
      ...state,
      priceOnPay: action.payload,
    }),
    // Order process
    orderProcessed: (state) => ({
      ...state,
      isProcessed: true,
      isProcessing: false,
    }),
    confirmOrder: (state) => ({
      ...state,
      priceOnPay: null,
    }),
    finishOrder: (state) => ({
      ...state,
      isFinished: true,
    }),
  },
  extraReducers: (builder) => {
    builder
      // Flights
      .addCase(toggleFlightClassAction, (state, action) =>
        updateFlightInfo(state, action.payload),
      )
      // Client
      .addCase(confirmClientAction, (state) => ({
        ...state,
        isClientConfirmed: true,
      }))
      .addCase(resetClientAction, (state) => ({
        ...state,
        isClientConfirmed: false,
      }))
      // Tourists
      .addCase(validationSuccessAction, (state) => ({
        ...state,
        isTouristsConfirmed: true,
      }))
      .addCase(changeTouristData, (state) => ({
        ...state,
        isTouristsConfirmed: false,
      }))
      .addCase(resetTouristConfirmedAction, (state) => ({
        ...state,
        isTouristsConfirmed: false,
      }))
      .addCase(clearPreviousPackageDataAction, (state) => ({
        ...state,
        flight_pair_id: undefined,
        flight_extras: null,
        flightInfo: null,
        insuranceType: null,
        coupon: null,
        discount_price: null,
        discount_type: null,
        errors: null,
        priceInfo: null,
        comment: {
          text: '',
          checkboxes: ORDER_COMMENT_CHECKBOXES,
        },
        jointOrder: {
          ordersAmount: 0,
          firstOrderNumber: 0,
          isFirstOrder: null,
        },
      }));
  },
});

export const {
  selectFlight,
  skipFlights,
  setFlightInfo,
  changeOrderComment,
  setOrdersAmount,
  setFirstOrderNumber,
  switchOrder,
  setFirstOrderField,
  prebuildOrder,
  prebuildOrderFail,
  prebuildOrderSuccess,
  prebuildOrderCompleteWithMindboxErrors,
  createOrder,
  createOrderSuccess,
  createOrderFail,
  updateOrder,
  updateOrderSuccess,
  setPaylateData,
  stashUserPriceBeforePay,
  orderProcessed,
  confirmOrder,
  finishOrder,
} = orderSlice.actions;

export default orderSlice.reducer;
