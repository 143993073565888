import { createAction } from '@reduxjs/toolkit';
import {
  VALIDATE_TOURIST_DATA,
  VALIDATE_TOURIST_ON_SERVER,
  VALIDATE_TOURIST_FIELD,
  SUBMIT_TOURIST,
  SET_TOURIST_VALIDATED,
  UPDATE_MOCK_TOURIST,
  RESET_TOURIST_CONFIRMED,
  SUBMIT_TOURISTS,
  SUBMIT_TOURIST_SIMPLE_CHECKOUT,
} from '../constants/tourists';
import type { SavedTourist } from '../client/types';

export const validateTouristDataAction = (payload) => ({
  type: VALIDATE_TOURIST_DATA,
  payload,
});

export const validateTouristOnServerAction = (payload) => ({
  type: VALIDATE_TOURIST_ON_SERVER,
  payload,
});

export const validateTouristFieldAction = (payload) => ({
  type: VALIDATE_TOURIST_FIELD,
  payload,
});

export const submitTouristAction = (payload) => ({
  type: SUBMIT_TOURIST,
  payload,
});

export const submitTourists = createAction(SUBMIT_TOURISTS);

export const submitTouristSimpleHotelCheckout = createAction(
  SUBMIT_TOURIST_SIMPLE_CHECKOUT,
);

export const setTouristValidatedAction = createAction<number>(
  SET_TOURIST_VALIDATED,
);

export const updateMockTouristAction = createAction<{
  index: number;
  visa_service: string;
}>(UPDATE_MOCK_TOURIST);

export const resetTouristConfirmedAction = createAction(
  RESET_TOURIST_CONFIRMED,
);

export const addSavedTourist = createAction<SavedTourist>('ADD_SAVED_TOURIST');

export const fetchSavedTourists = createAction('FETCH_SAVED_TOURISTS');

export const onTouristClickAction = createAction<number>('ON_TOURIST_CLICK');

export const confirmTouristsAction = createAction('CONFIRM_TOURISTS');
