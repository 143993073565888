import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  initPaymentFailAction,
  pay3dsFailAction,
  payUnitellerFailAction,
} from 'src/store/payment/reducer';
import type { ErrorStateType, ErrorType } from './types';
import { createOrderFail } from '../../actions/order';

const initialState: ErrorStateType = {
  isOpened: false,
  type: null,
  title: null,
  message: null,
  action: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showErrorPopup(state) {
      state.isOpened = true;
    },
    hideErrorPopup(state) {
      state.isOpened = false;
    },
  },
  extraReducers(builder) {
    const setError = (
      state,
      action: PayloadAction<{
        type?: ErrorType;
        title?: string;
        message: string;
        action?: string;
      }>,
    ): ErrorStateType => ({
      ...state,
      type: action.payload.type || null,
      action: action.payload.action || null,
      title: action.payload.title || null,
      message: action.payload.message,
    });
    builder.addCase(initPaymentFailAction, setError);
    builder.addCase(payUnitellerFailAction, setError);
    builder.addCase(pay3dsFailAction, setError);
    builder.addCase(createOrderFail, setError);
  },
});
