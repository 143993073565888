import { createSlice } from '@reduxjs/toolkit';

import { clearTouristData } from 'src/store/tourists/slice';

import type { ValidationStateType } from '../validation/types';

const initialState: ValidationStateType = [];

const removeTouristErrors = (state, idx) =>
  state.filter((error) => !error.path.startsWith(`[${idx}]`));

const removeTouristFieldErrors = (state, idx, field) =>
  state.filter((error) => {
    const [index, fieldName] = error.path.split('.');
    return index !== `[${idx}]` || fieldName !== field;
  });

const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    touristValidationFailed: (state, { payload }) => {
      const { errors, index } = payload;
      const touristErrors =
        errors?.map((error) => {
          error.path = `[${index}].${error.path}`;
          return error;
        }) || [];

      return [...removeTouristErrors(state, index), ...touristErrors];
    },
    touristValidationSuccess: (state, { payload }) => {
      const { index } = payload;
      return removeTouristErrors(state, index);
    },
    validateTouristFieldSuccess: (state, { payload }) => {
      const { index, field } = payload;
      return removeTouristFieldErrors(state, index, field);
    },
    validateTouristFieldFailed: (state, { payload }) => {
      const { index, field, error } = payload;
      const fieldError = { ...error, path: `[${index}].${field}` };

      return removeTouristFieldErrors(state, index, field).concat(fieldError);
    },
    touristsValidationFailed: (_, { payload }) => payload,
    touristsValidationSuccess: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addCase(clearTouristData, () => initialState),
});

export const { name } = validationSlice;
export const {
  touristValidationFailed,
  touristValidationSuccess,
  validateTouristFieldSuccess,
  validateTouristFieldFailed,
  touristsValidationFailed,
  touristsValidationSuccess,
} = validationSlice.actions;

export default validationSlice.reducer;
