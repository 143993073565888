/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import type { ErrorType } from 'src/routes/Checkout/store/domains/error/types';
import type { StatsPayRequest } from '@lt/api/dist/methods/stats/types';
import type { PaymentStateType } from './types';

const initialState: PaymentStateType = {
  cardInfo: {
    number: '',
    expMonth: '',
    expYear: '',
    cvv: '',
  },
  isCardValid: false,
  show3dsPopup: false,
  '3ds': {},
  '3dsHidden': {},
  '3dsPaRes': null,
  '3dsCres': null,
  paymentMethodList: [],
  isProcessing: false,
  paymentMethod: null,
  paymentAmount: null,
  orderId: null,
  orderPin: null,
  cardData: null,
  cardIsValid: null,
  orderPaymentId: null,
  error: null,
  show3dsHiddenPopup: null,
  unitellerPaymentId: null,
  isPaid: null,
  formUrl: '',
};
const name = 'payment';

// Для оплаты на странице чекаута и сертификата

// reducer + actions
const payment = createSlice({
  name,
  initialState,
  reducers: {
    // Payment amount:
    setPaymentAmount(state, action: PayloadAction<number | null>) {
      state.paymentAmount = action.payload;
    },

    // Payment method:
    setPaymentMethod(
      state,
      action: PayloadAction<StatsPayRequest['payment_method']>,
    ) {
      state.paymentMethod = action.payload;
    },
    setPaymentMethodList(state, action: PayloadAction<unknown[]>) {
      state.paymentMethodList = action.payload;
    },

    // Create order:
    setOrderInfo(
      state,
      action: PayloadAction<{
        orderId: number | null;
        pin: string | null;
      }>,
    ) {
      const {
        payload: { orderId, pin },
      } = action;
      state.orderId = orderId;
      state.orderPin = pin;
    },

    // Card data:
    setCardDataAction(
      state,
      action: PayloadAction<{
        number: string;
        expMonth: string;
        expYear: string;
        cvv: string;
      }>,
    ) {
      state.cardData = action.payload;
    },
    setCardValidityAction(state, action: PayloadAction<boolean>) {
      state.cardIsValid = action.payload;
    },

    // Default:
    initPaymentAction(state) {
      state.isProcessing = true;
    },
    initPaymentSuccessAction(
      state,
      action: PayloadAction<{
        orderPaymentId: string | null;
      }>,
    ) {
      const { orderPaymentId } = action.payload;
      state.orderPaymentId = orderPaymentId;
    },
    initPaymentFailAction(
      state,
      action: PayloadAction<{
        type?: ErrorType;
        title?: string;
        message: string;
        action?: string;
      }>,
    ) {
      state.isProcessing = false;
      state.error = {
        type: action.payload.type || null,
        message: action.payload.message,
        action: action.payload.action || null,
      };
    },

    payUnitellerAction() {
      // state.isProcessing = true;
    },

    // 3DS payments:
    pay3dsFailAction(
      state,
      action: PayloadAction<{
        type?: ErrorType;
        title?: string;
        message: string;
        action?: string;
      }>,
    ) {
      state.isProcessing = false;
      state.error = {
        type: action.payload.type || null,
        message: action.payload.message,
        action: action.payload.action || null,
      };
    },
    pay3dsSubmitParesAction(state, action: PayloadAction<string | null>) {
      state['3dsPaRes'] = action.payload;
    },
    pay3dsSubmitCresAction(state, action: PayloadAction<string | null>) {
      state['3dsCres'] = action.payload;
    },

    // Show 3ds popup:
    show3dsPopupAction(state, action: PayloadAction<unknown>) {
      state.show3dsPopup = true;
      state['3ds'] = action.payload;
    },
    hide3dsPopupAction(state) {
      state.show3dsPopup = false;
    },

    // Show 3ds hidden popup:
    show3dsHiddenPopupAction(state, action) {
      state.show3dsHiddenPopup = true;
      state['3dsHidden'] = action.payload;
    },
    hide3dsHiddenPopupAction(state) {
      state.show3dsHiddenPopup = false;
    },

    // Uniteller payments:
    payUnitellerSuccessAction(state, action: PayloadAction<string | null>) {
      state.unitellerPaymentId = action.payload;
    },
    payUnitellerFailAction(
      state,
      action: PayloadAction<{
        type?: ErrorType;
        title?: string;
        message: string;
        action?: string;
      }>,
    ) {
      state.isProcessing = false;
      state.error = {
        type: action.payload.type || null,
        message: action.payload.message,
        action: action.payload.action || null,
      };
    },

    setFormUrlAction(
      state,
      action: PayloadAction<PaymentStateType['formUrl']>,
    ) {
      state.formUrl = action.payload;
    },

    // Order is paid:
    orderPaidSuccessAction(state) {
      state.isProcessing = false;
      state.isPaid = true;
    },
  },
});

// export actions:
export const startPayment = createAction(`${name}/startPayment`);
// 3DS payments:
export const pay3dsAction = createAction(`${name}/pay3dsAction`);

// 3DS 2.0:
export const submit3dsDataAction = createAction(`${name}/submit3dsDataAction`);
export const submit3dsDataFailAction = createAction(
  `${name}/submit3dsDataFailAction`,
);

export const {
  // Payment amount:
  setPaymentAmount,

  // Payment method:
  setPaymentMethod,
  setPaymentMethodList,

  // Order info:
  setOrderInfo,

  // Card data:
  setCardDataAction,
  setCardValidityAction,

  // Default:
  initPaymentAction,
  initPaymentSuccessAction,
  initPaymentFailAction,

  payUnitellerAction,

  // 3DS payments:
  pay3dsFailAction,
  pay3dsSubmitParesAction,
  pay3dsSubmitCresAction,

  // Show 3ds popup:
  show3dsPopupAction,
  hide3dsPopupAction,

  // Show 3ds Hidden popup:
  show3dsHiddenPopupAction,
  hide3dsHiddenPopupAction,

  // Uniteller payments:
  payUnitellerSuccessAction,
  payUnitellerFailAction,

  // Paylate
  setFormUrlAction,

  // Order is paid:
  orderPaidSuccessAction,
} = payment.actions;

export default payment.reducer;
