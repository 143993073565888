import { CDN_PATH } from 'src/constants/common';

export const randBgNumber = () => {
  const minBgNumber = 7;
  const maxBgNumber = 10;

  return Math.floor(
    Math.random() * (maxBgNumber - minBgNumber + 1) + minBgNumber,
  );
};

export const generateWLBgURL = (n) => `${CDN_PATH}/head/bg-${n}.jpg`;
