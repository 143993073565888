import bg_preview_7 from './bg-preview-7.jpg';
import bg_preview_8 from './bg-preview-8.jpg';
import bg_preview_9 from './bg-preview-9.jpg';
import bg_preview_10 from './bg-preview-10.jpg';

export default {
  7: bg_preview_7,
  8: bg_preview_8,
  9: bg_preview_9,
  10: bg_preview_10,
};
