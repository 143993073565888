import type { SimpleInterpolation } from 'styled-components';
import type {
  PostClientSendOtpSms,
  PostClientSendOtpSmsResponse,
  PostClientRestorePasswordResponse,
  GetClientResendEmailConfirmationResponse,
} from '@lt/api/dist/methods/client/types';
import type { AxiosError } from 'axios';
import type {
  Ref,
  Dispatch,
  SetStateAction,
  ComponentProps,
  ComponentType,
} from 'react';
import type { MailConfirmationForm } from './components/MailConfirmationForm';

export enum AuthFormType { // todo дополнить
  Login = 'Login', // окно ввода email/телефона
  LogOut = 'LogOut', // юзер успешно разлогинился
  LoginWithSMSCode = 'LoginWithSMSCode', // окно ввода sms-кода
  LoginWithPassword = 'LoginWithPassword', // окно ввода пароля
  Registration = 'Registration', // окно регистрации
  Failure = 'Failure', // окно ошибки, когда запрос упал
  SendSmsCodeAgain = 'SendSmsCodeAgain', // выслать новый код подтверждения
  SuccessLogin = 'SuccessLogin', // юзер успешно залогинился
  MailConfirmation = 'MailConfirmation', // юзер успешно зарегистрировался
  ConfirmProcess = 'ConfirmProcess', // окно с прелоадером во время проверки токенов подтверждения почты и установки пароля
  CreatePassword = 'CreatePassword', // окно ввода пароля
  ResetPassword = 'ResetPassword', // окно сброса пароля
  PasswordConfirmation = 'PasswordConfirmation', // юзер успешно установил пароль
  ChangedMailConfirmation = 'ChangedMailConfirmation', // юзер успешно сменил и подтвердил почту
  RestorePassword = 'RestorePassword', // окно восстановления пароля
  ResendEmailConfirmationForm = 'ResendEmailConfirmationForm', // окно подтверждения неподтвержденной ранее почты при логине
  SetPhoneForConfirm = 'SetPhoneForConfirm', // окно ввода телефона перед подтверждением
  ConfirmPhoneWithSMSCode = 'ConfirmPhoneWithSMSCode', // окно подтверждения телефона
  ConfirmAndLoginPhoneWithSMSCode = 'ConfirmAndLoginPhoneWithSMSCode', // окно подтверждения телефона с авторизацией
  InvalidToken = 'InvalidToken', // невалидный токен подтверждения почты/восстановления пароля
  SessionExpired = 'SessionExpired', // время сессии истекло
  RestorePasswordAfterLeak = 'RestorePasswordAfterLeak', // окно, призывающее сменить пароль
}

export const AuthEnumToAnalytics = {
  Login: 'auth_login',
  LogOut: 'auth_logout',
  LoginWithSMSCode: 'auth_login_with_sms_code',
  LoginWithPassword: 'auth_login_with_password',
  Registration: 'auth_registration',
  Failure: 'auth_failure',
  SendSmsCodeAgain: 'auth_send_sms_code_again',
  SuccessLogin: 'auth_success_login',
  MailConfirmation: 'auth_mail_confirmation',
  ConfirmProcess: 'auth_confirm_process',
  CreatePassword: 'auth_create_password',
  ResetPassword: 'auth_reset_password',
  PasswordConfirmation: 'auth_password_confirmation',
  ChangedMailConfirmation: 'auth_changed_mail_confirmation',
  RestorePassword: 'auth_restore_password',
  ResendEmailConfirmationForm: 'auth_resend_email_confirmation_form',
  SetPhoneForConfirm: 'auth_set_phone_for_confirm',
  ConfirmPhoneWithSMSCode: 'auth_confirm_phone_with_sms_code',
  ConfirmAndLoginPhoneWithSMSCode: 'auth_confirm_and_login_phone_with_sms_code',
  InvalidToken: 'auth_invalid_token',
  SessionExpired: 'auth_session_expired',
  RestorePasswordAfterLeak: 'auth_restore_password_after_leak',
} as const;

export enum LoginFormType {
  Phone = 'Phone', // форма логина по номеру телефона
  Email = 'Email', // форма логина по почте
}

export enum LoginFormTypeForAnalytics {
  Email = 'email',
  Phone = 'phone',
}

export enum TokenType {
  ConfirmationToken = 'ConfirmationToken',
  ResetPasswordToken = 'ResetPasswordToken',
}

export enum ClientType {
  UnconfEmailUnconfPhone = 'UnconfEmailUnconfPhone', // клиент с неподтвержденной почтой и неподтвержденным телефоном
  ConfEmailUnconfPhone = 'ConfEmailUnconfPhone', // клиент с подтвержденной почтой и неподтвержденным телефоном
  UnconfEmailConfPhone = 'UnconfEmailConfPhone', // клиент с неподтвержденной почтой и подтвержденным телефоном
  ConfEmailConfPhone = 'ConfEmailConfPhone', // клиент с подтвержденной почтой и подтвержденным телефоном
  Unauthorized = 'Unauthorized', // неавторизованный клиент
}

export enum PageType {
  Client = 'CLIENT_PAGE', // страница личного кабинета
  Packages = 'PACKAGES_PAGE', // страница пакетов
  Default = 'HOME_PAGE', // главная страница
  Checkout = 'CHECKOUT_PAGE', // страница чекаута
  Gift = 'GIFT_PAGE', // страница подарочного сертификата
  Testimonials = 'TESTIMONIALS_PAGE', // страница отзывов
  Order = 'ORDER_PAGE', // страница заказа
  Services = 'SERVICES_PAGE', // страница оплаты
}

export enum ConfirmedData {
  Email = 'email',
  Password = 'password',
}

export enum RequestStatus {
  Idle = 'idle',
  Submitting = 'submitting',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface SharedState {
  id?: number;
  country?: string;
  phone: string;
  phone_mask?: string;
  unconfirmed_phone?: string;
  email: string;
  unconfirmed_email?: string;
  password: string;
  name: string;
  surname: string;
  signUpType: LoginConfig['type'] | AuthProvider | null;
}

export interface QueryParams {
  inviter_id?: number;
}

export enum AuthProvider {
  TBank = 'tbank',
  VK = 'vkontakte',
  OK = 'odnoklassniki',
  Github = 'github',
  Yandex = 'yandex',
  Apple = 'apple',
}

export enum AuthMethodsForAnalytics {
  TBank = AuthProvider.TBank,
  VK = AuthProvider.VK,
  OK = AuthProvider.OK,
  Github = AuthProvider.Github,
  Yandex = AuthProvider.Yandex,
  Apple = AuthProvider.Apple,
  Phone = LoginFormTypeForAnalytics.Phone,
  Email = LoginFormTypeForAnalytics.Email,
}

export interface LoginConfig {
  phone?: string;
  email?: string;
  type: 'email' | 'phone';
}

export interface AuthConfig {
  login: string;
  password: string;
}

export interface ConfirmPhoneConfig {
  confirmedPhone: string;
  shouldHideAuthPopup: boolean;
}

export interface GetNewCodeConfig
  extends Pick<PostClientSendOtpSms, 'event' | 'client_id'> {
  phone?: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
  captchaCallbackSuccess?: () => void;
}

export type AuthLayoutCSSItem = {
  ref?: Ref<null> | null;
  isMobile?: boolean;
  loginFormCSS?: ReadonlyArray<SimpleInterpolation>;
  wrapperCSS?: ReadonlyArray<SimpleInterpolation>;
  containerCSS?: ReadonlyArray<SimpleInterpolation>;
};

export type AuthLayoutCSS = {
  [key in AuthFormType]?: AuthLayoutCSSItem;
};

export type SetSharedState = Dispatch<SetStateAction<SharedState>>;
export type SetFormType = Dispatch<SetStateAction<AuthFormType>>;
export type SetRequestStatus = Dispatch<SetStateAction<RequestStatus>>;

export type HandleGetNewCode = (
  config: GetNewCodeConfig,
) => Promise<PostClientSendOtpSmsResponse>;

export type HandleRestorePassword = (
  email: string | undefined,
  hasPasswordLeaked: boolean,
) => Promise<PostClientRestorePasswordResponse>;

export type HandleResendEmailConfirmation = (
  email: string | undefined,
) => Promise<GetClientResendEmailConfirmationResponse>;

export type CustomAuthComponents = {
  CustomMailConfirmationForm?: ComponentType<
    ComponentProps<typeof MailConfirmationForm>
  >;
};
