import React, { lazy, Suspense } from 'react';
import ErrorBoundary from 'src/error/components/ErrorBoundary';

const DesktopAppBanner = lazy(
  () => import(/* webpackChunkName: "DesktopAppBanner" */ '.'),
);

const LazyDesktopAppBanner = (): JSX.Element => (
  <ErrorBoundary showDialog={false}>
    <Suspense fallback={null}>
      <DesktopAppBanner />
    </Suspense>
  </ErrorBoundary>
);

export default LazyDesktopAppBanner;
