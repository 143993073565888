import React, { useState, useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { useDispatch, useSelector, connect } from 'react-redux';

import {
  getRating,
  getReviews,
  getShortLink,
} from 'src/store/appBanners/selectors';
import {
  getDeeplinkAction,
  goToMobileAppAction,
  moveToAppContinuedWeb,
  moveToAppOpenedApp,
  moveToAppShown,
} from 'src/store/appBanners/slice';
import {
  getAllowShowMobileAppPopup,
  getAppName,
  getIsAppBannerEnabled,
  getIsWebview,
  getMobilePlatform,
} from 'src/store/view/selectors';

import { useLockedBody } from 'usehooks-ts';
import { isFromMobileAppFlag } from 'src/utils/uriUtils';

import { MODULE_NAME } from 'src/constants';
// import { useShowBannerController } from 'src/hooks/useShowBannerController';
import MobileAppPopup from './MobileAppPopup';
import { isShowed, isFromMobileApp, setFromMobileApp } from './utils';
import { TITLE } from './constants';
// import { KEY_COOKIE as COOKIE_BANNER_COOKIE } from '../CookiesBanner/constants';

/*
 * Показываем каждый раз, когда пользователь заходит на сайт, открыв заново браузер
 */

const MobileAppPopupContainer = (props: PropsFromRedux) => {
  const [hiddenState, setHiddenState] = useState(true);
  const [, setLocked] = useLockedBody(false);
  const { allowShow, page, isWebView, isAppBannerEnabled } = props;

  const dispatch = useDispatch();
  const platform = useSelector(getMobilePlatform);
  const isFromMobile = isFromMobileAppFlag() || isFromMobileApp();

  // const { isShowBanner: isShownCookieBanner } =
  //   useShowBannerController(COOKIE_BANNER_COOKIE);

  useEffect(() => {
    const showBanner = () => {
      const isShowedBanner = !isFromMobile ? isShowed() : false;
      setHiddenState(isShowedBanner);
      setLocked(!isShowedBanner);
      dispatch(goToMobileAppAction());
      dispatch(getDeeplinkAction());
    };

    // На главной странице баннер показываем сразу
    // if (allowShow && !isFromMobile && page && !isShownCookieBanner) {
    if (allowShow && !isFromMobile && page && isAppBannerEnabled) {
      if (
        page === MODULE_NAME.HOME ||
        page === MODULE_NAME.MODERN_MOBILE_HOME
      ) {
        showBanner();
      } else {
        setTimeout(() => showBanner(), 5000);
      }
    }

    if (isFromMobile) {
      setFromMobileApp();
    }
    // }, [platform, dispatch, setLocked, page, isShownCookieBanner]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, dispatch, setLocked, page]);

  const rating = useSelector(getRating);
  const reviews = useSelector(getReviews);

  const onClickButton = () => {
    dispatch(moveToAppOpenedApp());
    setLocked(false);
    setHiddenState(true);
  };

  const onStaySiteButton = () => {
    setLocked(false);
    dispatch(moveToAppContinuedWeb());
  };

  const linkToApp = useSelector(getShortLink);

  if (
    hiddenState ||
    !allowShow ||
    isWebView ||
    !linkToApp ||
    !isAppBannerEnabled
  ) {
    return null;
  }

  return (
    <MobileAppPopup
      title={TITLE}
      onClickButton={onClickButton}
      rating={rating}
      reviews={reviews}
      linkToApp={linkToApp}
      onStaySiteButton={onStaySiteButton}
      trackShow={() => dispatch(moveToAppShown())}
    />
  );
};

const mapStateToProps = (state) => ({
  allowShow: getAllowShowMobileAppPopup(state),
  page: getAppName(state),
  isWebView: getIsWebview(state),
  isAppBannerEnabled: getIsAppBannerEnabled(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MobileAppPopupContainer);
