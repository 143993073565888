import React, { Suspense, lazy, useEffect, useState } from 'react';

import { Image } from '@lt/components';
import SideNavMenu from 'src/components/Header/components/HeaderMenu/components/SideNavMenu';

import TourGuide from 'src/components/Banners/TourGuide';

import HeaderWriteUs from 'src/components/Header/components/HeaderMenu/components/HeaderWriteUs';
import { useSidebarOpener } from 'src/components/Layout/hooks/useSidebarOpener';
import Auth from 'src/routes/Mobile';
import CookiesBanner from 'src/components/Banners/CookiesBanner';
import CountriesList from '../CountriesList';
import Header from '../Header';
import Relinking from '../Relinking';
import SearchForm from '../SearchForm';
import DynamicDataWrapper from './components/DynamicDataWrapper';
import HomeLtBottomPromo from './components/HomeLtBottomPromo';

import type { MobileSearchBlockProps } from './types';
import styles from './mobileSearchBlock.module.css';
import { NpsState } from '../NpsForm/NpsForm';

const NpsForm = lazy(
  () => import(/* webpackChunkName: "NpsForm" */ '../NpsForm'),
);

const MobileSearchBlock = ({
  isWhitelabel,
  isWebView,
  isLoggedIn,
  onLogOut,
  partnerLogo,
  onShowAuthPopup,
  npsState,
}: MobileSearchBlockProps): JSX.Element => {
  const { isSidebarOpened, openSidebar, closeSidebar } = useSidebarOpener();
  const [isSSR, setIsSSR] = useState(true);

  /* force rerender on client, issue: https://github.com/vercel/next.js/issues/7417#issuecomment-509411508 */
  useEffect(() => {
    setIsSSR(false);
  }, []);

  useEffect(() => {
    if (window && window.PARTNER && window.PARTNER.id === 1190)
      window.parent.postMessage(
        { name: 'frameRenderSuccess', success: true },
        '*',
      );
  }, []);

  return (
    <>
      <section className={styles.sectionView}>
        {isSidebarOpened && (
          <SideNavMenu
            onShowAuthPopup={onShowAuthPopup}
            onLogOut={onLogOut}
            closeSidebar={closeSidebar}
            isWhitelabel={isWhitelabel}
            isLoggedIn={isLoggedIn}
          />
        )}
        <div className={styles.topGradient} />
        <div className={styles.bottomGradient} />
        <Image
          className={styles.image}
          src="https://jsapi.cdn.level.travel/lt-modules-assets/main/header_bg_portrait.webp"
          fallback="https://jsapi.cdn.level.travel/lt-modules-assets/main/header_bg_portrait.jpg"
          alt="preview image"
        />
        {!isSSR && !isWhitelabel && <CookiesBanner />}
        {!isSSR && npsState !== NpsState.Hide && (
          <Suspense fallback={<div />}>
            <NpsForm npsState={npsState} />
          </Suspense>
        )}

        {!isWebView && (
          <Header
            redirectLogoLink="/"
            onBurgerClick={openSidebar}
            partnerLogo={partnerLogo}
            isWhitelabel={isWhitelabel}
          />
        )}
        {!isSSR && (
          <DynamicDataWrapper>
            <SearchForm />
          </DynamicDataWrapper>
        )}
      </section>
      {!isWebView && (
        <>
          <div className={styles.tourGuideBannerWrapper}>
            <TourGuide isMobile />
          </div>
          <Relinking isMobile />
          <HeaderWriteUs />
        </>
      )}
      {!isSSR && <Auth />}
      {!isWhitelabel && (
        <>
          <HomeLtBottomPromo isMobile />
          <CountriesList isMobile />
        </>
      )}
    </>
  );
};

export default MobileSearchBlock;
