import type { CurrencyType } from '@lt/components/utils/currency';
import type { SearchType } from '@lt/api/dist/types/searchParams';
import { createSelector } from '@reduxjs/toolkit';
import {
  getFromImmutableStateCompatible,
  getImmutableCompatible,
} from 'src/utils/immutableUtils';
import { isFromMobileAppFlag } from 'src/utils/uriUtils';
import type { MODULE_NAME } from 'src/constants';
import { SEARCH_TYPE_NAMES } from 'src/constants/search';

const getViewParams = (state) => getImmutableCompatible(state, 'view');

export const getIsMobile = createSelector(
  [getViewParams],
  (view) => !!getImmutableCompatible(view, 'isMobile'),
);

export const getIsWhitelabel = createSelector(
  [getViewParams],
  (view) => !!getImmutableCompatible(view, 'isWhitelabel'),
);

export const getIsWebview = createSelector(
  [getViewParams],
  (view) => !!getImmutableCompatible(view, 'isWebview'),
);

export const getIsIframe = createSelector(
  [getViewParams],
  (view) => !!getImmutableCompatible(view, 'isIframe'),
);

export const getAppName = createSelector(
  getViewParams,
  (view): (typeof MODULE_NAME)[keyof typeof MODULE_NAME] =>
    getImmutableCompatible(view, 'app'),
);

export const getMobilePlatform = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'platform'),
);

export const searchTypeSelector = createSelector(
  getViewParams,
  (view): SearchType => getImmutableCompatible(view, 'searchType'),
);

export const getIsWidgetShown = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'isWidgetShown'),
);

export const getIsTransparent = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'isTransparent'),
);

export const getIsFontsBlack = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'isFontsBlack'),
);

export const _getAvailableSearchTypes = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'availableSearchTypes'),
);

export const getAvailableSearchTypes = getFromImmutableStateCompatible<
  SearchType[]
>(_getAvailableSearchTypes);

export const getIsHomepage = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'isHomepage'),
);

export const getCurrencySymbol = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'currencySymbol'),
);

export const getCurrencyCode = createSelector(
  getViewParams,
  (view): CurrencyType => getImmutableCompatible(view, 'currencyCode'),
);

export const getIsHotelOnly = createSelector(
  searchTypeSelector,
  (type) => type === 'hotel',
);

export const getCustomCashbackIcon = createSelector(
  getViewParams,
  (view) => getImmutableCompatible(view, 'cashbackIcon') || {},
);

export const getCustomCashbackIconUrl = createSelector(
  getCustomCashbackIcon,
  (customCashback) => getImmutableCompatible(customCashback, 'cashbackIconURL'),
);

export const getActivateCashbackForPartner = createSelector(
  [getCustomCashbackIcon, getIsWhitelabel],
  (customCashback, isWhitelabel) => {
    if (isWhitelabel && customCashback) {
      return !getImmutableCompatible(
        customCashback,
        'disabledCashbackForPartner',
      );
    }

    return true;
  },
);

export const getCashbackLabel = createSelector(
  getCustomCashbackIcon,
  (customCashback) => getImmutableCompatible(customCashback, 'cashbackLabel'),
);

export const getCashbackTooltip = createSelector(
  getCustomCashbackIcon,
  (customCashback) => getImmutableCompatible(customCashback, 'cashbackTooltip'),
);

export const getAllowShowMobileAppPopup = createSelector(
  getIsMobile,
  (isMobile) => isMobile && !isFromMobileAppFlag(),
);

export const getIsShowSubscription = createSelector(
  [getIsWhitelabel, searchTypeSelector],
  (isWhitelabel, searchType) =>
    !isWhitelabel && searchType !== SEARCH_TYPE_NAMES.HOTEL,
);

export const getInitialOpenedField = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'initialOpenedField'),
);

export const getIsHotelCheckout = createSelector(
  getAppName,
  (appName) => appName === 'hotel_checkout',
);

export const getIsAppBannerEnabled = createSelector(getViewParams, (view) =>
  getImmutableCompatible(view, 'isAppBannerEnabled'),
);
