import { LocalizationProvider } from '@lt/localization';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MODULE_NAME } from 'src/constants';
import ErrorBoundary from 'src/error/components/ErrorBoundary';
import { initAppAction } from 'src/store/view/actions';
import { createGlobalStyle } from 'styled-components';
import { freezeBody } from 'src/globalStyles/freezeBody';
import MobileSearchBlock from './components/MobileSearchBlock';

import Desktop from './components/Desktop';
import SearchForm from './components/SearchForm';

import { parseWindow } from './store/view/actions';
import { calculationVHDuringRendering } from './utils/calculationVHDuringRendering';

// TODO: Add Layout component when page will be rendered with React

const GlobalStyle = createGlobalStyle<{ $isMobile: boolean }>`
  ${freezeBody}
  :root {
    --white: #ffffff;
    --light-gray: ${({ $isMobile }) => ($isMobile ? '#e6e9ee' : '#c2c5cc')};
    --fine-text-size: 10px;
    --small-text-size: 12px;
    --large-text-size: 22px;
  }
`;

export interface HomeAppProps {
  isMobile?: boolean;
  isModernDesktop?: boolean;
  platform: string;
}

const HomeApp = ({
  isMobile = false,
  platform,
  isModernDesktop = false,
}: HomeAppProps): JSX.Element => {
  const dispatch = useDispatch();
  const appName = MODULE_NAME.HOME;

  useEffect(() => {
    calculationVHDuringRendering();
  }, []);

  useEffect(() => {
    dispatch(initAppAction({ appName, platform }));
    dispatch(parseWindow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <GlobalStyle $isMobile={isMobile} />
      {isMobile && (
        <ErrorBoundary>
          <LocalizationProvider>
            <MobileSearchBlock />
          </LocalizationProvider>
        </ErrorBoundary>
      )}
      {isModernDesktop && !isMobile && (
        <ErrorBoundary>
          <LocalizationProvider>
            <Desktop />
          </LocalizationProvider>
        </ErrorBoundary>
      )}
      {!isMobile && !isModernDesktop && (
        <ErrorBoundary>
          <LocalizationProvider>
            <SearchForm />
          </LocalizationProvider>
        </ErrorBoundary>
      )}
    </>
  );
};

export default HomeApp;
