import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  HotelStars,
  MobilePopupBottom,
  Link,
  Image,
} from '@lt/components';
import { Star } from '@lt/components/icons-react/userflow';

import logoPng from 'src/images/logo/logo.png';
import logoWebp from 'src/images/logo/logo.webp';
import { i18n } from '@lt/localization';
import type { IMobileAppPopup } from './types';

const MobileAppPopup = ({
  title,
  onClickButton,
  onStaySiteButton,
  trackShow,
  rating = '',
  reviews = '',
  linkToApp,
}: IMobileAppPopup) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    trackShow();
    // задезейблил так как trackShow это функция и ссылка на нее можем меняться
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setIsVisible(false);
    onStaySiteButton();
  };
  const ratingAsNumber = Number(rating.replace(',', '.'));

  const starValue = Math.trunc(ratingAsNumber);
  const viewBoxValue = `0 0 ${(ratingAsNumber % 1) * 25} 24`;

  return (
    <MobilePopupBottom visible={isVisible} onClick={() => setIsVisible(false)}>
      <StyledHeader>
        <HeaderTitle>{title}</HeaderTitle>
      </StyledHeader>
      <StyledSeparator />
      <StyledContent>
        <Content>
          <Logo src={logoWebp} fallback={logoPng} alt="Level.Travel logo" />
          <StyledAppContent>
            <Title>{i18n.t('mobile_apps_banner.app_information.title')}</Title>
            <StyledSubtitle>
              {i18n.t('mobile_apps_banner.app_information.description')}
            </StyledSubtitle>
            <StyledAppData>
              <StyledHotelStars value={starValue} size={12} />
              <StyledStar height={12} viewBox={viewBoxValue} />
              <StyledReviews>{reviews}</StyledReviews>
            </StyledAppData>
          </StyledAppContent>
        </Content>
        <Link href={linkToApp} target="_blank">
          <GoToAppButton primary size="flex" isMobile onClick={onClickButton}>
            {i18n.t('mobile_apps_banner.openAppButton')}
          </GoToAppButton>
        </Link>
      </StyledContent>
      <Description>{i18n.t('mobile_apps_banner.description')}</Description>
      <DescriptionNote>
        {i18n.t('mobile_apps_banner.descriptionNote')}
      </DescriptionNote>
      <StyledStayButton onClick={handleClick}>
        {i18n.t('mobile_apps_banner.continueOnWebsiteButton')}
      </StyledStayButton>
    </MobilePopupBottom>
  );
};

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #17181a;
`;

const Description = styled.div`
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
  white-space: pre-line;
`;

const DescriptionNote = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #919499;
  text-align: center;
`;

const StyledSeparator = styled.span`
  margin: 8px;
  display: block;
  height: 1px;
  background-color: #d8d8d8;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 4px;
`;

const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #17181a;
  margin: 16px auto;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Content = styled.div`
  display: flex;
  padding: 8px 4px 8px 0;
`;

const GoToAppButton = styled(Button)`
  width: 90px;
  height: 46px;
  font-size: 16px;
  margin-top: 8px;
`;

const StyledStayButton = styled(Button)`
  color: #007aff;
  margin: auto;
  border: none;
  background-color: inherit;
`;

const StyledAppContent = styled.div`
  width: 130px;
`;

const StyledSubtitle = styled.div`
  color: #4d4d4d;
`;

const StyledAppData = styled.div`
  div:last-child {
    margin-left: 10px;
  }
  display: flex;
`;

const StyledReviews = styled.div`
  color: #919499;
`;

const StyledHotelStars = styled(HotelStars)`
  margin-right: 1px;
`;

const StyledStar = styled(Star).attrs(({ viewBox }) => ({ viewBox }))`
  path {
    fill: #ffd600;
    stroke: #ffd600;
  }
`;

const Logo = styled(Image)`
  margin: 0 8px 0 0;
  border: 1px solid #ebecf0;
  border-radius: 15px;
  width: 54px;
  height: 54px;
`;

export default MobileAppPopup;
