import {
  orderPaidSuccessAction,
  pay3dsFailAction,
  payUnitellerFailAction,
  startPayment,
} from 'src/store/payment/reducer';

export const actionsToEvents = {
  [payUnitellerFailAction.type]: 'payment_failed',
  [pay3dsFailAction.type]: 'payment_failed',
  [startPayment.type]: 'payment_started',
  [orderPaidSuccessAction.type]: 'payment_success',
} as const;
