import React, { useEffect, useState } from 'react';

import UserflowTheme from '@lt/components/themes/userflow';

import LazyDesktopAppBanner from 'src/components/Banners/DesktopAppBanner/LazyDesktopAppBanner';
import MobileAppPopupContainer from 'src/components/Banners/MobileAppPopup/MobileAppPopupContainer';
import LazyCigarette from 'src/components/Cigarette/components/LazyCigarette';
import SearchTypeTabs from 'src/components/SearchTypeTabs';

import useLockedBody from 'src/hooks/useLockedBody';

import { isSSR } from 'src/helpers/ssr';

import { TABS_PAGES } from 'src/components/SearchTypeTabs/constants';

import type { SearchType } from 'src/types/search';
import classnames from 'classnames';
import type { PropsFromRedux } from '.';
import styles from './searchForm.module.css';

interface SearchFormProps extends PropsFromRedux {
  className?: string;
}

const SearchForm = (props: SearchFormProps): JSX.Element => {
  const {
    isMobile,
    isWhitelabel,
    className = '',
    availableSearchTypes,
    submitCigarette,
    isAppBannerEnabled,
  } = props;

  const [searchType, setSearchType] = useState<SearchType>('package');
  const [isWizardOpen, setIsWizardOpen] = useState(false);

  const searchParamsUrl = new URLSearchParams(window.location.search);
  const searchTypeFromUrl = searchParamsUrl.get('search_type') as SearchType;

  const [, setLocked] = useLockedBody();

  useEffect(() => {
    if (searchTypeFromUrl) {
      setSearchType(searchTypeFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (availableSearchTypes && !searchTypeFromUrl) {
      setSearchType(availableSearchTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSearchTypes]);

  useEffect(() => {
    if (isMobile) {
      setLocked(isWizardOpen);
    }
  }, [isWizardOpen, setLocked, isMobile]);

  return (
    <UserflowTheme>
      <div className={classnames({ [styles.searchFormMobile]: isMobile })}>
        <SearchTypeTabs
          className={classnames({
            [styles.searchTabs]: !isMobile,
            [styles.searchTabsMobile]: isMobile,
          })}
          selectedTab={searchType}
          setSelectedTab={setSearchType}
          variant={{
            mobile: isMobile,
            wl: isWhitelabel,
            collapsed: false,
            page: TABS_PAGES.MAIN,
          }}
        />
        <LazyCigarette
          className={className}
          isMainPage
          isMobile={isMobile}
          searchType={searchType}
          trackSubmit={submitCigarette}
          setIsWizardOpen={setIsWizardOpen}
          isWizardOpen={isWizardOpen}
        />
        <MobileAppPopupContainer />
        {!isMobile && !isSSR && isAppBannerEnabled ? (
          <LazyDesktopAppBanner />
        ) : null}
      </div>
    </UserflowTheme>
  );
};

export default SearchForm;
