import type { SagaReturnType } from 'redux-saga/effects';
import { put, select } from 'redux-saga/effects';
import { api } from 'src/api';
import { APPLICATION_VERSION } from 'src/error/config/sentry/runTimeConfig';
import { getClientStatsData } from 'src/store/client/selectors';
import { getPaymentMethod } from 'src/store/payment/selectors';
import { getIsMobile } from 'src/store/view/selectors';
import { getOrderId } from '../selectors/order';
import type { StatsPayActions } from './types';
import { actionsToEvents } from './constants';
import { showErrorPopupAction } from '../domains/error/actions';

export function* sendPayAnalytics(action: StatsPayActions) {
  try {
    const { type, payload } = action;
    const event = actionsToEvents[type];
    if (event === 'payment_failed') yield put(showErrorPopupAction());

    const { message } = payload || {};
    const client: SagaReturnType<typeof getClientStatsData> = yield select(
      getClientStatsData,
    );
    const paymentMethod: SagaReturnType<typeof getPaymentMethod> = yield select(
      getPaymentMethod,
    );
    const partnerId = window.PARTNER ? window.PARTNER.id : null;
    const isMobile: SagaReturnType<typeof getIsMobile> = yield select(
      getIsMobile,
    );
    const orderId: SagaReturnType<typeof getOrderId> = yield select(getOrderId);

    if (!paymentMethod) throw new Error('No payment method provided!');
    if (!APPLICATION_VERSION)
      throw new Error('No application version provided!');

    yield api.postStatsPayment({
      event,
      client,
      payment_method: paymentMethod,
      release_version: APPLICATION_VERSION,
      partner_id: partnerId,
      is_mobile: isMobile,
      order_id: Number(orderId),
      error: message,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('stats/pay error: ', e);
  }
}
