import Cookies from 'js-cookie';
import { KEY_COOKIE, FROM_MOBILE_APP_COOKIE } from './constants';

export const setShowed = () => {
  Cookies.set(KEY_COOKIE, JSON.stringify(false));
};

export const isShowed = (): boolean => {
  const cookieValue = Cookies.get(KEY_COOKIE);
  const partnerParamsUrl = new URLSearchParams(window.location.search);
  const promocodeMark = partnerParamsUrl.get('utm_content') === 'promocode';
  const isSetCookieValue = !cookieValue || Boolean(JSON.parse(cookieValue));
  if (!isSetCookieValue || promocodeMark) {
    return true;
  }
  setShowed();
  return false;
};

export const isShow = (): boolean => {
  const cookieValue = Cookies.get(KEY_COOKIE);
  const value = cookieValue ? Boolean(JSON.parse(cookieValue)) : true;
  return Boolean(value);
};

export const setFromMobileApp = () => {
  Cookies.set(FROM_MOBILE_APP_COOKIE, JSON.stringify(true));
};

export const isFromMobileApp = (): boolean => {
  const cookieValue = Cookies.get(FROM_MOBILE_APP_COOKIE);
  return cookieValue ? Boolean(JSON.parse(cookieValue)) : false;
};
