import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { useTranslation } from '@lt/localization';

import { getPartnerPromoTitle } from 'src/store/menu/selectors';
import classnames from 'classnames';
import styles from './wlHeaderDescription.module.css';

const HEADER_PROMO_CLASSNAME = 'header_promo';
const HEADER_PROMO_TITLE = 'header_promo-title';
const HEADER_PROMO_TEXT = 'header_promo-text';

const WLHeaderDescription = ({ partnerPromoTitle }) => {
  const { t } = useTranslation();

  return partnerPromoTitle ? (
    <div
      className={styles.customDescription}
      dangerouslySetInnerHTML={{ __html: partnerPromoTitle }}
    />
  ) : (
    <div
      className={classnames(HEADER_PROMO_CLASSNAME, styles.customDescription)}
    >
      <div className={HEADER_PROMO_TITLE}>{t('homeHeader.promoTitle')}</div>
      <div className={HEADER_PROMO_TEXT}>{t('homeHeader.promoText')}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerPromoTitle: getPartnerPromoTitle(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WLHeaderDescription);
