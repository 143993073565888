import React, { lazy, Suspense } from 'react';
import type { FC } from 'react';
import classnames from 'classnames';

import HeaderMenu, {
  HeaderMenuCategory,
} from '@lt/components/components/HeaderMenu';

import { isSSR } from 'src/helpers/ssr';
import HeaderFeedback from '../../HeaderFeedback';
import HeaderWishlist from '../../HeaderWishlist';

import type { IHeaderMenu } from '../types';
import styles from './desktopMenu.module.css';

const HeaderAuth = lazy(
  () =>
    import(
      /* webpackChunkName: "HeaderAuth" */ 'src/components/Header/components/HeaderAuth'
    ),
);

const DesktopMenu: FC<IHeaderMenu> = ({
  services,
  help,
  className,
  isCollapsed,
  showAuthorizationBlock = true,
  showWishlist = true,
  showFeedback,
}) => (
  <HeaderMenu
    className={classnames(className, { [styles.headerMenu]: isCollapsed })}
    isCompact={isCollapsed}
  >
    {/* https://leveltravel.atlassian.net/browse/LT-31772 */}
    {showFeedback && <HeaderFeedback isCompact={isCollapsed} />}
    {services && services.items && services.items.length !== 0 && (
      <HeaderMenuCategory
        className={classnames(styles.services, {
          [styles.fullServices]: !isCollapsed,
        })}
        isCompact={isCollapsed}
        {...services}
      />
    )}
    {showWishlist && <HeaderWishlist isCompact={isCollapsed} />}
    {help && help.items && help.items.length !== 0 && (
      <HeaderMenuCategory
        className={classnames(styles.help)}
        isCompact={isCollapsed}
        {...help}
      />
    )}
    {showAuthorizationBlock && !isSSR && (
      <Suspense fallback={<div />}>
        <HeaderAuth isCompact={isCollapsed} />
      </Suspense>
    )}
  </HeaderMenu>
);

export default DesktopMenu;
