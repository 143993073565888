import { i18n } from '@lt/localization';

import {
  GENDER,
  NATIONALITY_CONSTANTS,
  VISA_SERVICE_TYPE,
} from 'src/constants';

import { TOURIST_FIELDS as T } from './tourists';

/**
 * TODO: Разделение стора для турового и отельного чекаута
 * @see https://leveltravel.atlassian.net/browse/LT-34762
 */
export const SUBMIT_TOURISTS_CHANGE = 'SUBMIT_TOURISTS_CHANGE';

// Extras constants:
export const ADD_EXTRA = 'ADD_EXTRA';
export const REMOVE_EXTRA = 'REMOVE_EXTRA';
export const TOGGLE_EXTRA = 'TOGGLE_EXTRA';

// Order constants:
export const GO_TO_ORDER_PAGE = 'GO_TO_ORDER_PAGE';

// Update order constants:
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

// Package constants:
export const SUBMIT_PACKAGE = 'SUBMIT_PACKAGE';

// Sub payments:
export const PAY_BY_DISCOUNT = 'PAY_BY_DISCOUNT';
export const ADD_COUPON = 'ADD_COUPON';
export const ADD_BONUS = 'ADD_BONUS';
export const CHECK_PRICE_REDUCTION_BY_CASHBACK =
  'CHECK_PRICE_REDUCTION_BY_CASHBACK';

// Mock – part of features. DO NOT TOUCH:
export const MOCK_CLIENT = {
  name: 'Демо',
  surname: 'Демо',
  email: 'order_test@level.travel',
  phone: '+7(000)000-00-00',
  comment: 'this is demo client',
  bonus_score: 0,
} as const;

export const MOCK_TOURIST = {
  [T.NAME]: 'Demo',
  [T.SURNAME]: 'Demo',
  [T.GENDER]: GENDER.MALE,
  [T.BIRTHDAY]: '01.01.1991',
  [T.DOC_NUMBER]: '00 0000000',
  [T.DOC_EXPIRES_DATE]: '01.01.2020',
  [T.VISA_SUPPORT]: VISA_SERVICE_TYPE.NO_VISA,
  [T.CITIZENSHIP]: NATIONALITY_CONSTANTS.RU,
} as const;

export const ORDER_ERROR_CODE = {
  10: i18n.t('orderErrorCode.10'),
  11: i18n.t('orderErrorCode.11'),
  14: i18n.t('orderErrorCode.14'),
  21: i18n.t('orderErrorCode.21'),
  22: i18n.t('orderErrorCode.22'),
  23: i18n.t('orderErrorCode.23'),
  34: i18n.t('orderErrorCode.34'),
  37: i18n.t('orderErrorCode.37'),
  40: i18n.t('orderErrorCode.40'),
  41: i18n.t('orderErrorCode.41'),
  42: i18n.t('orderErrorCode.42'),
  43: i18n.t('orderErrorCode.43'),
} as const;

export const ORDER_COMMENT_CHECKBOXES = [
  {
    text: i18n.t('checkout.orderCommentCheckboxes.viewRoom'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.roomWithBalcony'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.quietRoom'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.birthday'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.honeymoonTrip'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.babyBed'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.bigBed'),
  },
  {
    text: i18n.t('checkout.orderCommentCheckboxes.separateBed'),
  },
];

export const BUILD_ORDER_TIMEOUT = 60 * 1000;
