import React from 'react';
import styled from 'styled-components';

import { Tab } from '@lt/components';

import { SEARCH_TYPE_NAMES } from 'src/constants/search';
import type { SearchType } from '../../types/search';

import { getStyles } from './utils';
import type { TabsVariant } from './types';
import { TABS_PAGES } from './constants';

interface SearchTypeTabProps {
  name: SearchType;
  label: string;
  altLabel?: string;
  Icon: React.MemoExoticComponent<
    (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  >;
  isActive?: boolean;
  variant: TabsVariant;
  onClick?: () => null;
}

export const SearchTypeTab = ({
  name,
  label,
  altLabel,
  Icon,
  isActive,
  variant,
  onClick,
}: SearchTypeTabProps) => {
  const { mobile, page, collapsed } = variant;

  const StyledIcon = styled(Icon)<{
    $variant: TabsVariant;
    $isActive?: boolean;
  }>`
    width: 24px;
    height: 24px;
    margin-right: 4px;
    flex: 1;

    path {
      ${({ $variant, $isActive }) => getStyles('ICON', $variant, $isActive)}
    }
  `;

  return (
    <StyledTab
      onClick={onClick}
      value={name}
      $variant={variant}
      $isActive={isActive}
    >
      {collapsed && mobile ? null : (
        <StyledIcon $variant={variant} $isActive={isActive} />
      )}
      {page === 'HOTEL' ? altLabel || label : label}
      {name === SEARCH_TYPE_NAMES.TRIP ? (
        <StyledNewIcon>NEW</StyledNewIcon>
      ) : null}
    </StyledTab>
  );
};

const StyledTab = styled(Tab).attrs({ functional: true })<{
  $variant: TabsVariant;
  $isActive?: boolean;
}>`
  cursor: pointer;
  padding: ${({ $variant: { mobile, page } }) =>
    !mobile && page === TABS_PAGES.MAIN ? '8px 22px' : '8px 16px'};
  border: none;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: ${({ $variant: { mobile, collapsed } }) => {
    if (!mobile) return '8px 8px 0 0';
    return collapsed ? '4px 4px 0 0' : '40px';
  }};

  ${({ $variant, $isActive }) => getStyles('TAB', $variant, $isActive)}

  button {
    display: flex;
    align-items: center;
  }
  ${({ name }) => name === 'hotel' && 'display: flex'}
`;

export const StyledNewIcon = styled.div`
  font-weight: 50;
  letter-spacing: 0.1em;
  margin-left: 4px;
  font-size: 8px;
  color: white;
  background: linear-gradient(90deg, #ff9c41 0%, #f82c69 100%);
  border-radius: 4px;
  padding: 4px;
`;
