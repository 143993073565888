import type { ClientStateType } from './types';

export const clientDTO = (
  dataClient: unknown,
  dataUser: unknown,
): ClientStateType => {
  if (dataClient && typeof dataClient === 'object') {
    return {
      auth_token:
        'auth_token' in dataClient && typeof dataClient.auth_token === 'string'
          ? dataClient.auth_token
          : null,
      avatar:
        'avatar' in dataClient && typeof dataClient.avatar === 'string'
          ? dataClient.avatar
          : null,
      email:
        'email' in dataClient && typeof dataClient.email === 'string'
          ? dataClient.email
          : null,
      id:
        'id' in dataClient && typeof dataClient.id === 'number'
          ? dataClient.id
          : null,
      name:
        'name' in dataClient && typeof dataClient.name === 'string'
          ? dataClient.name
          : null,
      surname:
        'surname' in dataClient && typeof dataClient.surname === 'string'
          ? dataClient.surname
          : null,
      phone:
        'phone' in dataClient && typeof dataClient.phone === 'string'
          ? dataClient.phone
          : null,
      readable_phone:
        'readable_phone' in dataClient &&
        typeof dataClient.readable_phone === 'string'
          ? dataClient.readable_phone
          : null,
      unconfirmed_phone:
        'unconfirmed_phone' in dataClient &&
        typeof dataClient.unconfirmed_phone === 'string'
          ? dataClient.unconfirmed_phone
          : null,
      has_password:
        'has_password' in dataClient &&
        typeof dataClient.has_password === 'boolean'
          ? dataClient.has_password
          : null,
      bonus_score:
        'bonus_score' in dataClient &&
        typeof dataClient.bonus_score === 'number'
          ? dataClient.bonus_score
          : null,
      unconfirmed_email:
        'unconfirmed_email' in dataClient &&
        typeof dataClient.unconfirmed_email === 'string'
          ? dataClient.unconfirmed_email
          : null,
      confirmed_at:
        'confirmed_at' in dataClient &&
        typeof dataClient.confirmed_at === 'string'
          ? dataClient.confirmed_at
          : null,
      coefficient_for_room_upgrade:
        'coefficient_for_room_upgrade' in dataClient &&
        typeof dataClient.coefficient_for_room_upgrade === 'number'
          ? dataClient.coefficient_for_room_upgrade
          : 0,
      name_en:
        'name_en' in dataClient && typeof dataClient.name_en === 'string'
          ? dataClient.name_en
          : null,
      surname_en:
        'surname_en' in dataClient && typeof dataClient.surname_en === 'string'
          ? dataClient.surname_en
          : null,
      isAdmin: Boolean(
        dataUser && typeof dataUser === 'object' && 'id' in dataUser,
      ),
      showAuthPopup: false,
      showLogOutPopup: false,
    };
  }
  return {
    auth_token: null,
    avatar: null,
    email: null,
    id: null,
    name: null,
    surname: null,
    phone: null,
    readable_phone: null,
    unconfirmed_phone: null,
    has_password: null,
    bonus_score: null,
    unconfirmed_email: null,
    confirmed_at: null,
    coefficient_for_room_upgrade: 0,
    name_en: null,
    surname_en: null,
    showAuthPopup: false,
    showLogOutPopup: false,
    isAdmin: Boolean(
      dataUser && typeof dataUser === 'object' && 'id' in dataUser,
    ),
  };
};
