import { isSSR } from 'src/helpers/ssr';

const headerBanner = isSSR ? undefined : window.headerBanner;

const hasHeaderBanner = !!headerBanner;

// Header is fixed
export const OLD_MOBILE_HEADER_OFFSET = 60;
export const HEADER_OFFSET = 76;
export const HEADER_OFFSET_SEARCH = 128;
export const MOBILE_HEADER_OFFSET = 74;
export const MOBILE_PROGRESS_BAR_OFFSET = 3;
export const FULL_MOBILE_OFFSET =
  MOBILE_HEADER_OFFSET + MOBILE_PROGRESS_BAR_OFFSET;
export const TOP_OFFSET = 22;
export const DESKTOP_OFFSET_AFTER_HEADER = 20;
export const HEADER_BANNER_OFFSET = hasHeaderBanner ? 60 : 0;

export const SEARCH_WIDGET_PLACE_ID = 'popup-lt-search-widget';

export const countFullDesktopOffset = (isHeaderBannerHidden: boolean) => {
  if (isHeaderBannerHidden)
    return HEADER_OFFSET_SEARCH + DESKTOP_OFFSET_AFTER_HEADER;
  return (
    HEADER_OFFSET_SEARCH + DESKTOP_OFFSET_AFTER_HEADER + HEADER_BANNER_OFFSET
  );
};
