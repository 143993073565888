import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import type {
  MobileAppDataProps,
  StoreAppDateProps,
} from 'src/components/Banners/MobileAppPopup/types';
import {
  GET_DEEPLINK,
  GO_MOBILE_APP_POPUP,
  MOVE_TO_APP_CONTINUED_WEB,
  MOVE_TO_APP_OPENED_APP,
  MOVE_TO_APP_SHOWN,
} from './constants';

const initialStoreState: StoreAppDateProps = {
  success: false,
  rating: '',
  reviews: '',
};

const initialState: MobileAppDataProps = {
  storeData: initialStoreState,
  shortLink: '',
};

const appBannerSlice = createSlice({
  name: 'bannerAppData',
  initialState,
  reducers: {
    setMobileAppData: (
      state: MobileAppDataProps,
      action: PayloadAction<MobileAppDataProps['storeData']>,
    ) => {
      state.storeData = action.payload;
    },
    setShortLink: (
      state: MobileAppDataProps,
      action: PayloadAction<MobileAppDataProps['shortLink']>,
    ) => {
      state.shortLink = action.payload;
    },
  },
});

export const { setMobileAppData, setShortLink } = appBannerSlice.actions;

export const goToMobileAppAction = createAction(GO_MOBILE_APP_POPUP);
export const getDeeplinkAction = createAction(GET_DEEPLINK);
export const moveToAppShown = createAction(MOVE_TO_APP_SHOWN);
export const moveToAppOpenedApp = createAction(MOVE_TO_APP_OPENED_APP);
export const moveToAppContinuedWeb = createAction(MOVE_TO_APP_CONTINUED_WEB);

const { name, reducer } = appBannerSlice;
export { reducer as default, name };
