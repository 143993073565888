import React, { Suspense, lazy } from 'react';

import { Image } from '@lt/components';

import flightMapFallback from 'src/images/homepage_map/homepage_map.png';
import flightMapSrc from 'src/images/homepage_map/homepage_map.webp';

import { isSSR } from 'src/helpers/ssr';

import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import FooterAppBanners from 'src/components/Layout/components/FooterAppBanners';
import classnames from 'classnames';
import CookiesBanner from 'src/components/Banners/CookiesBanner';
// import AdfoxGalleryBanner from '../AdfoxGalleryBanner';
import CountriesList from '../CountriesList';
import DynamicDataWrapper from '../MobileSearchBlock/components/DynamicDataWrapper';
import HomeLtBottomPromo from '../MobileSearchBlock/components/HomeLtBottomPromo';
import Relinking from '../Relinking';
import SearchForm from '../SearchForm';
import StatisticsWidget from '../StatisticsWidget';

import type { PropsFromRedux as IDesktop } from '.';
import { WLHeaderBackground } from '../Header/WL/WLHeaderBackground';
import WLHeaderDescription from '../Header/WL/WLHeaderDescription';
import styles from './desktop.module.css';
import { NpsState } from '../NpsForm/NpsForm';

const NpsForm = lazy(
  () => import(/* webpackChunkName: "NpsForm" */ '../NpsForm'),
);

const Desktop = ({
  isWhitelabel,
  isStatisticWidgetDisabled,
  npsState,
}: IDesktop): JSX.Element => (
  <>
    <div className={styles.headContainer}>
      {!isSSR && (
        <>
          {isWhitelabel ? (
            <WLHeaderBackground />
          ) : (
            <Image
              className={styles.flightMap}
              src={flightMapSrc}
              fallback={flightMapFallback}
            />
          )}
          {npsState !== NpsState.Hide && (
            <Suspense fallback={<div />}>
              <NpsForm npsState={npsState} />
            </Suspense>
          )}
          <div className={styles.headContentContainer}>
            {!isWhitelabel && <CookiesBanner />}
            <div
              className={classnames(styles.headerShadow, {
                [styles.headerShadowWhitelabel]: isWhitelabel,
              })}
            >
              <div className={styles.container}>
                <Header />
              </div>
            </div>
            <div className={styles.container}>
              {isWhitelabel ? <WLHeaderDescription /> : null}
              <DynamicDataWrapper>
                <SearchForm />
              </DynamicDataWrapper>
            </div>
          </div>
        </>
      )}
    </div>
    {/* {!isWhitelabel && <AdfoxGalleryBanner />} */}
    <div className={styles.contentContainer}>
      {!isStatisticWidgetDisabled && <StatisticsWidget />}
      <Relinking isMobile={false} />
      {!isWhitelabel && (
        <>
          <HomeLtBottomPromo />
          <CountriesList />
        </>
      )}
    </div>
    <FooterAppBanners />
    <Footer />
  </>
);

export default Desktop;
