import React, { useEffect, useState } from 'react';
import { randBgNumber, generateWLBgURL } from 'src/helpers/generateWLBgURL';

import previews from 'src/images/wl_header_previews';
import classnames from 'classnames';
import styles from './wlHeaderBackground.module.css';

/**
 * Нужно сохранить данные классы и структуру,
 * поскольку bg может устанавливаться скриптом, приходящим с админки
 */

const HEADER_BG_IMAGE_CLASSNAME = 'header_bg-image';
const HEADER_BG_PRELOADER_CLASSNAME = styles.headerBgPreloader;
const HEADER_BG_PRELOADER_CLASSNAME_LOADED = styles.headerBgPreloaderLoaded;

export const WLHeaderBackground = () => {
  const [preloaderBgClassname, setPreloaderBgClassname] = useState(
    HEADER_BG_PRELOADER_CLASSNAME,
  );

  const [bgNumber] = useState(randBgNumber());

  useEffect(() => {
    const img = new Image();

    img.src = generateWLBgURL(bgNumber);

    const onLoadImg = () => {
      setPreloaderBgClassname(
        `${HEADER_BG_PRELOADER_CLASSNAME} ${HEADER_BG_PRELOADER_CLASSNAME_LOADED}`,
      );
    };

    img.addEventListener('load', onLoadImg);

    return () => {
      img.removeEventListener('load', onLoadImg);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classnames(
        HEADER_BG_IMAGE_CLASSNAME,
        styles.wlHeaderBgWrapper,
      )}
      style={{ backgroundImage: `url(${generateWLBgURL(bgNumber)})` }}
    >
      <div
        className={classnames(preloaderBgClassname, styles.wlHeaderBg)}
        style={{ backgroundImage: `url(${previews[bgNumber]})` }}
      />
    </div>
  );
};
