import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaReturnType } from 'redux-saga/effects';
import { call, select, takeLatest } from 'redux-saga/effects';

import { getItem, setItem } from '@lt/components/helpers/storage';
import { storageKeys } from '@lt/widget/components/Cigarette/constants';
import type { Values } from '@lt/widget/components/Cigarette/context';

import { SEARCH_TYPE_NAMES } from 'src/constants/search';
import { dataLayerPush } from 'src/helpers/gtm';
import type { SearchType } from 'src/types/search';

import { APPLICATION_VERSION } from 'src/error/config/sentry/runTimeConfig';
import { getUserId } from 'src/utils/getUserId';
import { api } from '../../api';
import { getClient, getClientStatsData } from '../client/selectors';
import type { initAppAction } from '../view/actions';
import { INIT_APP } from '../view/constants';
import { getAppName, getIsHotelOnly, getIsMobile } from '../view/selectors';
import { sendPageviewAction } from './actions';
import { SEND_PAGEVIEW, SUBMIT_CIGARETTE, SWITCH_TAB } from './constants';

import type { IStatsClient } from './types';

const GEO_KEY = 'lt-geoposition';

/**
 * 1. Пытаемся получить координаты в браузере
 * 2. Дергаем getReferencesClientCity чтобы получить город
 * 3. Сохраняем в localstorage, чтобы lt-tracker смог обработать значение
 *
 * @see https://github.com/LevelTravel/lt-tracker/blob/master/src/index.js#L229-L243
 */
function* fetchUserPosition() {
  const mock = {
    city: {
      name_en: '',
    },
    location: {
      lat: null,
      long: null,
    },
  };
  try {
    const data = localStorage.getItem(storageKeys.departure);
    if (data) {
      const departure = JSON.parse(data);
      const { success, ...geoData } = yield api.getReferencesClientCity({});

      if (success) {
        setItem({ key: GEO_KEY, payload: geoData });
      } else {
        setItem({
          key: GEO_KEY,
          payload: {
            city: {
              name_en: departure?.value ?? '',
            },
            location: {
              lat: null,
              long: null,
            },
          },
        });
      }
    } else {
      setItem({ key: GEO_KEY, payload: mock, ttl: 3 });
    }
  } catch (error) {
    // если геоданные получить не удалось, сохраняем пустое значение на 3 дня
    setItem({ key: GEO_KEY, payload: mock, ttl: 3 });
  }
}

/**
 * Запрашиваем геопозицию пользователя
 * Если данные сохранены в хранилище, то ручку не дергаем */
function* handleInitApp() {
  const data = getItem({ key: GEO_KEY });
  if (data?.location?.lat && data?.location?.long) {
    return;
  }

  yield fetchUserPosition();
}

/**
 * Сбор аналитики по переключению табов
 */
function handleSwitchSearchTab(
  action: PayloadAction<{
    previousSearchType: SearchType;
    searchType: SearchType;
    url: string;
    isMobile: boolean;
  }>,
) {
  const { previousSearchType, searchType, url, isMobile } = action.payload;
  const searchTypeStr = `${previousSearchType} to ${searchType}`;

  dataLayerPush({
    event: 'switch_tab',
    switch_tab_params: {
      search_type: searchTypeStr,
      url,
      mobile: isMobile,
    },
  });
}

function* getCurrentModuleName() {
  const page: SagaReturnType<typeof getAppName> = yield select(getAppName);
  const isHotelOnly: SagaReturnType<typeof getIsHotelOnly> = yield select(
    getIsHotelOnly,
  );
  /**
   * На туровом чекауте нужно отправлять не страницу, а шаг чекаута
   * Отель и поиск разделяем на туровый и отельный
   */
  switch (page) {
    case 'package_checkout':
      return 'package_checkout_flights';
    case 'hotel':
      return isHotelOnly ? 'hotel_landing_hotels_only' : 'hotel_landing_tours';
    case 'search':
      return isHotelOnly ? 'search_hotels' : 'search_tours';
    default:
      return page;
  }
}

export type StepNamePage = Exclude<
  SagaReturnType<typeof getCurrentModuleName>,
  | 'confirm_email'
  | 'cancellation_policy'
  | 'warn_debt'
  | 'order_changing_request'
  | 'main_order'
  | 'pay_card'
  | 'modern_mobile_home'
  | 'modern_desktop_home'
>;

// Отправляем pageview для каждой страницы
export function* sendPageviewAnalytics(
  action:
    | ReturnType<typeof sendPageviewAction>
    | ReturnType<typeof initAppAction>,
) {
  try {
    const isClientStoreAvailable = Boolean(yield select(getClient));
    const clientData: SagaReturnType<typeof getClientStatsData> =
      isClientStoreAvailable
        ? yield select(getClientStatsData)
        : { uuid: getUserId() };

    const client: IStatsClient = {
      id: clientData.id || undefined,
      email: clientData.email || undefined,
      phone: clientData.phone || undefined,
      uuid: getUserId(),
    };
    const isMobile = yield select(getIsMobile);
    const { referrer } = document;
    const partnerId = window.PARTNER ? window.PARTNER.id : null;
    const stepName: StepNamePage =
      action.type === SEND_PAGEVIEW
        ? action.payload
        : yield call(getCurrentModuleName);
    if (!stepName) return;
    if (!APPLICATION_VERSION)
      throw new Error('No application version provided!');

    yield api.postStatsPageview({
      client,
      is_mobile: isMobile,
      referrer,
      partner_id: partnerId,
      step_name: stepName,
      release_version: APPLICATION_VERSION,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * Сбор аналитики по поиску в сигарете
 */
function handleCigaretteSubmit(
  action: PayloadAction<{ values: Values; searchType: SearchType }>,
) {
  const { values, searchType } = action.payload;
  const { destination } = values;

  if (searchType !== SEARCH_TYPE_NAMES.TRIP) return;

  dataLayerPush({
    event: 'trip_search',
    custom_params: {
      destination,
    },
  });
}

export default function* analyticsSaga() {
  yield takeLatest(INIT_APP, handleInitApp);
  yield takeLatest([INIT_APP, sendPageviewAction], sendPageviewAnalytics);
  yield takeLatest(SWITCH_TAB, handleSwitchSearchTab);
  yield takeLatest(SUBMIT_CIGARETTE, handleCigaretteSubmit);
}
